import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import STORAGEKEY from "../config/APP/app.config";
import { ApiGet, ApiPost } from "../helper/API/ApiData";
import AuthStorage from "../helper/AuthStorage";
import { Login_User } from "../redux/reducers/authReducer";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { toast } from 'react-toastify';
import CryptoJS from "crypto-js";


interface Props {
    showSignup?: boolean,
    onHideSignnew?: any
}
const Register: React.FC<Props> = ({
    showSignup,
    onHideSignnew
}) => {

    const registerForm = {
        first_name: "",
        middle_name:"",
        last_name:"",
        firm_name: "",
        invite_code: "",
        email: "",
        password: "",
        confirmpassword: "",
        mobile: "",
        pan_no: "",
        postal_code: "",
        gst_no: null,
        gst_no_error: null,
        agreeteam: "",
        first_name_error: null,
        last_name_error: null,
        firm_name_error: null,
        invite_code_error: null,
        email_error: null,
        password_error: null,
        confirmpassword_error: null,
        mobile_error: null,
        pan_no_error: null,
        postal_code_error: null,
        city_error: null,
        state_error: null,
    }

    let navigate = useNavigate();

    const [formData, setFormData] = useState<any>(registerForm);

    const [state, setState] = useState<any>("");
    const [city, setCity] = useState<any>("");
    const [hide, setHide] = useState<any>(true);
    const [conformhide, setconformHide] = useState<any>(true);
    const [isRegister, setIsRegister] = useState<any>(false);
    const dispatch = useDispatch();

    const handleChange = (e: any) => {
        if (e.target.name == "agreeteam") {
            setFormData({ ...formData, [e.target.name]: e.target.checked });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    const handlePostalCodeInput = (e) => {
        e.target.value = e.target.value.replace(/[^0-9]/g, '');
    };
    const handleNameInput = (e) => {
        e.target.value=e.target.value.replace(/[^A-Za-z\s]/g, '');
    };
  
    const signup = () => {
        let updatedForm = { ...formData };
        for (let key in updatedForm) {
            if(typeof updatedForm[key] == 'string'){
                updatedForm[key] = updatedForm[key].trim();
            }
        }
        let namePattern=/^[A-Za-z\s]+$/;
        let mobilePattern = /^\+?[0-9]{10,}$/;
        let emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/;
        let postalCodePattern=/^\d{6}$/;

        let panVal = formData.pan_no;
        let firstFivePattern = /^[A-Za-z]{5}/;
        let firstFive = panVal.substring(0, 5);
        let sixtoNinePattern = /^[0-9]{4}/;
        let sixtoNine = panVal.substring(5, 9);
        let lastValPattern = /^[A-Za-z]{1}/;
        let lastVal = panVal.substring(9, 10);
        //GST validation
        
        let gstVal = formData.gst_no;
        if (gstVal != null && gstVal.trim() != '' ) {           
            if (gstVal.length == 15) {

                let statecode = gstVal.substring(0, 2);
                let patternstatecode = /^[0-9]{2}$/
                let threetoseven = gstVal.substring(2, 7);
                let patternthreetoseven = /^[a-zA-Z]{5}$/
                let seventoten = gstVal.substring(7, 11);
                let patternseventoten = /^[0-9]{4}$/
                let Twelveth = gstVal.substring(11, 12);
                let patternTwelveth = /^[a-zA-Z]{1}$/
                let Thirteen = gstVal.substring(12, 13);
                let patternThirteen = /^[1-9a-zA-Z]{1}$/
                let fourteen = gstVal.substring(13, 14);
                let patternfourteen = /^[zZ]{1}$/
                let fifteen = gstVal.substring(14, 15);
                let patternfifteen = /^[0-9a-zA-Z]{1}$/;
        
                   if (!patternstatecode.test(statecode)) {
                    updatedForm = {
                        ...updatedForm, gst_no_error: 'First two characters of GST No should be numbers'
                    };
                } else if (!patternthreetoseven.test(threetoseven)) {
                    updatedForm = {
                        ...updatedForm,
                        gst_no_error: 'Third to seventh characters of GST No should be alphabets'
                    };
                } else if (!patternseventoten.test(seventoten)) {
                    updatedForm = {
                        ...updatedForm,
                        gst_no_error: 'Eighth to Eleventh characters of GST No should be numbers'
                    };
                } else if (!patternTwelveth.test(Twelveth)) {
                    updatedForm = {
                        ...updatedForm,
                        gst_no_error: 'Twelveth character of GST No should be alphabet'
                    };
                } else if (!patternThirteen.test(Thirteen)) {
                    updatedForm = {
                        ...updatedForm,
                        gst_no_error: 'Thirteen characters of GST No can be either alphabet or numeric'
                    };
                } else if (!patternfourteen.test(fourteen)) {
                    updatedForm = {
                        ...updatedForm,
                        gst_no_error: 'Fourteen characters of GST No should be Z'
                    };
                } else if (!patternfifteen.test(fifteen)) {
                    updatedForm = {
                        ...updatedForm,
                        gst_no_error: 'Fifteen characters of GST No can be either alphabet or numeric'
                    };
                } else if (!gstVal.toUpperCase().includes(panVal.toUpperCase())) {
                    updatedForm = { ...updatedForm, gst_no_error: "Invalid GST No. Please check!" }
                } 
                }
                else{
                    updatedForm = {
                        ...updatedForm,
                        gst_no_error: 'Length should be restricted to 15 digits and should not allow anything more or less'
                    };
                }
        }   
        else (
            updatedForm = {
                ...updatedForm,
                gst_no_error: null
            })

        if (!formData.first_name ) {
            updatedForm = { ...updatedForm, first_name_error: "Please enter your first name" };
        }
        if (formData.first_name && !namePattern.test(formData.first_name)) {
            updatedForm = { ...updatedForm, first_name_error: "Please enter a valid name" };
        }
        // if (formData.middle_name == "") {
        //     updatedForm = { ...updatedForm, name_error: "Please enter a name" };
        // }
        if (!formData.last_name ) {
            updatedForm = { ...updatedForm, last_name_error: "Please enter your last name" };
        }
        if (formData.last_name && !namePattern.test(formData.last_name)) {
            updatedForm = { ...updatedForm, last_name_error: "Please enter a valid name" };
        }
        if (!formData.firm_name ) {
            updatedForm = { ...updatedForm, firm_name_error: "Please enter your firm name" };
        }
        if (formData.firm_name && !namePattern.test(formData.firm_name)) {
            updatedForm = { ...updatedForm, firm_name_error: "Please enter a valid name" };
        }
        if (!formData.email ) {
            updatedForm = { ...updatedForm, email_error: "Please enter your email" };
        }
        if (formData.email && !emailPattern.test(formData.email)) {
            updatedForm = { ...updatedForm, email_error: "Please enter a valid email" };
        }
        let passwordPatten = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
        if (formData.password && !passwordPatten.test(formData.password)) {
            updatedForm = { ...updatedForm, password_error: "Please enter Alphabet & Symbol" };
        }
        if (!formData.password ) {
            updatedForm = { ...updatedForm, password_error: "Please enter your password" };
        }
        if (formData.password && formData.confirmpassword == '') {
            updatedForm = { ...updatedForm, confirmpassword_error: "Please confirm password" };
        }
        if (formData.confirmpassword && (formData.password != formData.confirmpassword)) {
            updatedForm = { ...updatedForm, confirmpassword_error: "confirm password not match" };
        }
        if (!formData.mobile ) {
            updatedForm = { ...updatedForm, mobile_error: "Please enter your mobile no." };
        }
        if (formData.mobile && !mobilePattern.test(formData.mobile)) {
            updatedForm = { ...updatedForm, mobile_error: "Please enter valid mobile no." };
        }
        if (!formData.postal_code ) {
            updatedForm = { ...updatedForm, postal_code_error: "Please enter postal code" };
        }
        if (formData.postal_code && !postalCodePattern.test(formData.postal_code)) {
            updatedForm = { ...updatedForm, postal_code_error: "Please enter a valid postal code" };
        }

        if (!formData.pan_no ) {
            updatedForm = { ...updatedForm, pan_no_error: "Please enter your pan no" };
        } else if (panVal.length != 10) {
            updatedForm = {
                ...updatedForm,
                pan_no_error: 'Length should be restricted to 10 digits and should not allow anything more or less'
            };
        } else if (!firstFivePattern.test(firstFive)) {
            updatedForm = {
                ...updatedForm, pan_no_error: 'First Five characters of PAN No should be alphabets'
            };
        } else if (!sixtoNinePattern.test(sixtoNine)) {
            updatedForm = {
                ...updatedForm, pan_no_error: 'Six to Nine characters of PAN No should be number'
            };
        } else if (!lastValPattern.test(lastVal)) {
            updatedForm = {
                ...updatedForm,
                pan_no_error: 'Last characters of PAN No should be alphabets'
            };
        } else (
            updatedForm = {
                ...updatedForm,
                pan_no_error: null
            })

       
        // if (state == "") {
        //     updatedForm = { ...updatedForm, state_error: "Please enter a state" };
        // }
        // if (city == "") {
        //     updatedForm = { ...updatedForm, city_error: "Please enter a city" };
        // }
        setFormData(updatedForm)
        if (updatedForm.first_name_error == null &&
            updatedForm.last_name_error == null &&
            updatedForm.firm_name_error == null &&
            updatedForm.email_error == null &&
            updatedForm.password_error == null &&
            updatedForm.confirmpassword_error == null &&
            updatedForm.mobile_error == null &&
            updatedForm.pan_no_error == null &&
            updatedForm.gst_no_error == null &&
            //updatedForm.city_error == null &&
            updatedForm.postal_code_error == null) {
            const { confirmpassword,  first_name_error,last_name_error,
                firm_name_error,
                email_error,
                password_error,
                confirmpassword_error,
                mobile_error,
                pan_no_error,
                postal_code_error,
                city_error, state_error,invite_code_error,gst_no_error,
                  ...payload } = updatedForm;
            const secretPass = "XkhZG4fW2t2W";    
            const encrypt_pass = CryptoJS.AES.encrypt(
                    JSON.stringify(payload.password),
                    secretPass
                  ).toString();                    
                         
            const body = {
                ...payload,gst_no:(updatedForm.gst_no == "" || updatedForm.gst_no == " ") ? null : updatedForm.gst_no,
                state,
                city,
                password: encrypt_pass
            }
            ApiPost("auth/register", body)
                .then((res: any) => {
                    setFormData(registerForm)
                    setState("")
                    setCity("")
                    AuthStorage.setStorageData(
                        STORAGEKEY.token,
                        res.data.token,
                        true
                        //   stayLogedIn
                    );
                    AuthStorage.setStorageJsonData(
                        STORAGEKEY.userData,
                        res.data,
                        true
                        //   stayLogedIn
                    );
                    dispatch(Login_User(res.data))
                    navigate("/", { replace: true });
                })

                .catch((err) => {
                    let error = formData;
                    // toast.error(err?.error , {
                    //     position: "top-right",
                    //     theme: "colored"
                    //   })
                    if (err?.error == "Incorrect referral codes") {
                        error = { ...error, invite_code_error: "Please enter a correct referral code" };
                    }
                    if (err?.error == "User with email already exists") {
                        error = { ...error, email_error: "email already exists" };
                    }
                    setFormData({ ...formData, ...error })
                })
        }
        setIsRegister(false);
    }

    // const postget = async (e: any) => {
    //     const value = e.target.value;

    //     setFormData({ ...formData, [e.target.name]: e.target.value, postal_code_error: null, state_error: null, city_error: null })
    //     if (value.length >= 6 )
    //     ApiGet(`auth/get-post?pincode=${e.target.value}`)
    //     .then((res: any) => {
    
          
    //       setState(res.data?.data?.state ?? '');
    //       setCity(res.data?.data?.city ?? '');
    //     })
    // }

    const postget = async (e: any) => {
        const value = e.target.value;
        setFormData({ ...formData, [e.target.name]: e.target.value, postal_code_error: null, state_error: null, city_error: null })
        if (value.length >= 6 ) {
            const options = {
                method: 'GET',
                url: `https://india-pincode-with-latitude-and-longitude.p.rapidapi.com/api/v1/pincode/${value}`,
                headers: {
                  'X-RapidAPI-Key': 'b2572becadmshbaf647a375178edp179823jsneae8fa2a8334',
                  'X-RapidAPI-Host': 'india-pincode-with-latitude-and-longitude.p.rapidapi.com'
                }
              };
            await axios.request(options)
            .then((res: any) => {
                setState(res?.data[0]?.state);
                setCity(res?.data[0]?.district);
            })
            // await axios.get(`https://api.postalpincode.in/pincode/${value}`)
            //     .then((res: any) => {
            //         setState(res?.data[0]?.PostOffice[0]?.State);
            //         setCity(res?.data[0]?.PostOffice[0]?.District);
            //     })
        }
    }
    const privacyPolicy = () => {
        window.open("/privacypolicy");
    }

    const closeSignup = () => {
        navigate("/login")
    }
    return (
        <>
            {true &&
                <section className="auth-page-section" >

                    {/* START::DIALOG */}
                    <div className="popup-box max-w-[964px]">

                        {/* START::HEADER */}
                        <div className="flex items-center justify-between">
                            <h1 className="text-[22px] font-semibold ">Sign up</h1>
                            <button className="w-[30px] h-[30px]" onClick={() => closeSignup()}>
                                <img src="assets/close.svg" alt="" />
                            </button>
                        </div>
                        {/* END::HEADER */}

                        {/* START::FORM */}

                        <div className="space-y-[22px]">
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                                <div className="space-y-1">
                                    <label htmlFor="first_name" className={`input-label ${formData.first_name_error ? "text-red-500" : ""}`}>First Name<span style={{color:'red'}}>*</span></label>
                                    <div id="first_name" className={`input-wrap ${formData.first_name_error ? "border-red-500" : ""}`}>
                                        <img src="assets/user.svg" alt="" />
                                        <input type="text" name="first_name" value={formData.first_name} onInput={handleNameInput} onChange={(e: any) => {
                                            setFormData({ ...formData, first_name: e.target.value, first_name_error: null })
                                        }} className="w-full text-sm" placeholder="Enter your First name" />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.first_name_error}</p>
                                </div>

                                <div className="space-y-1">
                                    <label htmlFor="middle_name" className="input-label">Middle Name</label>
                                    <div id="middle_name" className="input-wrap " >
                                        <img src="assets/user.svg" alt="" />
                                        <input type="text" name="middle_name" value={formData.middle_name} onInput={handleNameInput} onChange={(e: any) => {
                                            setFormData({ ...formData, middle_name: e.target.value })
                                        }} className="w-full text-sm" placeholder="Enter your Middle name" />
                                    </div>
                                    {/* <p className="text-red-500 text-sm">{formData.name_error}</p> */}
                                </div>

                                <div className="space-y-1">
                                    <label htmlFor="last_name" className={`input-label ${formData.last_name_error ? "text-red-500" : ""}`}>Last Name<span style={{color:'red'}}>*</span></label>
                                    <div id="last_name" className={`input-wrap ${formData.last_name_error ? "border-red-500" : ""}`}>
                                        <img src="assets/user.svg" alt="" />
                                        <input type="text" name="last_name" value={formData.last_name} onInput={handleNameInput} onChange={(e: any) => {
                                            setFormData({ ...formData, last_name: e.target.value, last_name_error: null })
                                        }} className="w-full text-sm" placeholder="Enter your Last name" />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.last_name_error}</p>
                                </div>
                                
                                <div className="space-y-1">
                                    <label htmlFor="firm_name" className={`input-label ${formData.firm_name_error ? "text-red-500" : ""}`}>Firm Name<span style={{color:'red'}}>*</span></label>
                                    <div id="firm_name" className={`input-wrap ${formData.firm_name_error ? "border-red-500" : ""}`}>
                                        <img src="assets/firm.svg" alt="" />
                                        <input type="text" name="firm_name" value={formData.firm_name} onInput={handleNameInput} onChange={(e: any) => {
                                            setFormData({ ...formData, firm_name: e.target.value, firm_name_error: null })
                                        }} className="w-full text-sm" placeholder="Enter your Firm name" />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.firm_name_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="inviteCode" className={`input-label ${formData.invite_code_error ? "text-red-500" : ""}`}>Invite Code</label>
                                    <div id="inviteCode" className={`input-wrap ${formData.invite_code_error ? "border-red-500" : ""}`}>
                                        <img src="assets/invite-code.svg" alt="" />
                                        <input type="text" name="invite_code" value={formData.invite_code} onChange={(e: any) => {
                                            setFormData({ ...formData, invite_code: e.target.value, invite_code_error: null })
                                        }} className="w-full text-sm" placeholder="Apply Code" />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.invite_code_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="email" className={`input-label ${formData.email_error ? "text-red-500" : ""}`}>Email<span style={{color:'red'}}>*</span></label>
                                    <div id="email" className={`input-wrap ${formData.email_error ? "border-red-500" : ""}`}>
                                        <img src="assets/email.svg" alt="" />
                                        <input type="text" name="email" value={formData.email} onChange={(e: any) => {
                                            setFormData({ ...formData, email: e.target.value, email_error: null })
                                        }} className="w-full text-sm" placeholder="johndue@xyz.com" />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.email_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="password" className={`input-label ${formData.password_error ? "text-red-500" : ""}`}>Password<span style={{color:'red'}}>*</span></label>
                                    <div id="password" className={`input-wrap ${formData.password_error ? "border-red-500" : ""}`}>
                                        <img src="assets/lock.svg" alt="" />
                                        <input type={hide ? "password" : "text"} name="password" value={formData.password}  onChange={(e: any) => {
                                            setFormData({ ...formData, password: e.target.value, password_error: null })
                                        }} className="w-full text-sm" placeholder="XXXXXXXXXX" minLength={8}/>
                                        <img src="assets/eyes.svg" onClick={() => setHide(!hide)} alt="" />
                                    </div>
                                    <p className={`${!formData.password_error ? 'text-[12px] text-[#808080] mt-1' : 'text-red-500 text-sm'}`}>{!formData.password_error ? "At least 8 characters, with at least 1 uppercase, 1 lowercase and 1 number" : formData.password_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="confirmPassword" className={`input-label ${formData.confirmpassword_error ? "text-red-500" : ""}`}>Confirm Password<span style={{color:'red'}}>*</span></label>
                                    <div id="confirmPassword" className={`input-wrap ${formData.confirmpassword_error ? "border-red-500" : ""}`}>
                                        <img src="assets/lock.svg" alt="" />
                                        <input type='password' name="confirmpassword" value={formData.confirmpassword} onChange={(e: any) => {
                                            setFormData({ ...formData, confirmpassword: e.target.value, confirmpassword_error: null })
                                        }} className="w-full text-sm" placeholder="XXXXXXXXXX" />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.confirmpassword_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="mobile" className={`input-label ${formData.mobile_error ? "text-red-500" : ""}`}>Mobile No.<span style={{color:'red'}}>*</span></label>
                                    <div id="mobile" className={`input-wrap ${formData.mobile_error ? "border-red-500" : ""}`}>
                                        <img src="assets/phone.svg" alt="" />
                                        <input type="text" name="mobile" value={formData.mobile} maxLength={13} minLength={10} onChange={(e: any) => {
                                            setFormData({ ...formData, mobile: e.target.value, mobile_error: null })
                                        }} className="w-full text-sm" placeholder="Enter Mobile No." />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.mobile_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="pan_no" className={`input-label ${formData.pan_no_error ? "text-red-500" : ""}`}>PAN No.<span style={{color:'red'}}>*</span></label>
                                    <div id="pan_no" className={`input-wrap ${formData.pan_no_error ? "border-red-500" : ""}`}>
                                        <img src="assets/card.svg" alt="" />
                                        <input type="text" name="pan_no" maxLength={10} value={formData.pan_no} onChange={(e: any) => {
                                            setFormData({ ...formData, pan_no: e.target.value, pan_no_error: null })
                                        }} className="w-full text-sm uppercase" placeholder="Enter PAN No." />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.pan_no_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="postal_code" className={`input-label ${formData.postal_code_error ? "text-red-500" : ""}`}>Postal Code (Office)<span style={{color:'red'}}>*</span></label>
                                    <div id="postal_code" className={`input-wrap ${formData.postal_code_error ? "border-red-500" : ""}`}>
                                        <img src="assets/location.svg" alt="" />
                                        <input type="text" name="postal_code" maxLength={6} onInput={handlePostalCodeInput} value={formData.postal_code} onChange={(e: any) => { postget(e) }} className="w-full text-sm" placeholder="Enter Postal Code" />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.postal_code_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="gst" className={`input-label ${formData.gst_no_error ? "border-red-500" : ""}`}>GST No.</label>
                                    <div id="gst_no" className={`input-wrap ${formData.gst_no_error ? "border-red-500" : ""}`}>
                                        <img src="assets/card.svg" alt="" />
                                        <input type="text" name="gst_no" maxLength={15} value={formData.gst_no} onChange={(e: any) => {
                                            setFormData({ ...formData, gst_no: e.target.value , gst_no_error: null})
                                        }} className="w-full text-sm uppercase" placeholder="Enter GST No." />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.gst_no_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="state" className='input-label'>State</label>
                                    <div id="state" className='input-wrap'>
                                        <img src="assets/location.svg" alt="" />
                                        <input type="text" name="state" onChange={(e: any) => {
                                            setFormData({ ...formData,state:e.target.value })
                                            // setState(e.target.value);
                                        }} value={state} className="w-full text-sm" placeholder="Enter State" disabled/>
                                    </div>
                                    {/* <p className="text-red-500 text-sm">{formData.state_error}</p> */}
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="city" className='input-label'>City</label>
                                    <div id="city" className='input-wrap'>
                                        <img src="assets/location.svg" alt="" />
                                        <input type="text" name="city" onChange={(e: any) => {
                                            setFormData({ ...formData,city:e.target.value })
                                            // setCity(e.target.value)
                                        }} value={city} className="w-full text-sm" placeholder="Enter City" disabled/>
                                    </div>
                                    {/* <p className="text-red-500 text-sm">{formData.city_error}</p> */}
                                </div>


                            </div>
                            <div className="flex items-center justify-between text-sm">
                                <div className="flex items-center space-x-[10px]">
                                    <input type="checkbox" id="remember" name="agreeteam"
                                        onChange={(e) => {
                                            handleChange(e);
                                        }} className="" />
                                    <label htmlFor="remember" className="">I agree with <span className='privacy-policy cursor-pointer' onClick={()=>privacyPolicy()}>Privacy Policy.</span></label>
                                </div>
                            </div>
                        </div>

                        <LoadingButton
                            loading={isRegister}
                            loadingPosition="start"
                            className={isRegister ? "form-submit-button !bg-gray-500" : "form-submit-button"}
                            variant="outlined"
                            onClick={() => {
                                setIsRegister(true);
                                signup();
                            }}
                        >Sign up</LoadingButton>
                        {/* <button className="form-submit-button" onClick={() => {
                            setIsRegister(true);
                            signup();
                        }}>Sign up</button> */}
                        {/* <button className="form-submit-button" disabled={isStepDisable} onClick={signup}>Sign up</button> */}


                        <div className="flex items-center justify-center space-x-2">
                            <span>Already have an account?</span>
                            <a href="/" className="font-semibold text-secondary">Sign in</a>
                        </div>
                    </div>
                    {/* END::DIALOG */}

                </section >

            }
        </>
    )
}

export default Register