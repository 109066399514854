import { TableCell, TableRow, Tooltip } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import TableComponent from '../../components/Tables';
import { STATE } from '../../constants';
import { ApiGet, ApiPost, ApiPut } from '../../helper/API/ApiData';
import { getImageUrl, isEmpty } from '../../helper/util';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import { DatePicker } from '@mui/lab'
import DatePicker from "react-datepicker";
import { Stack, TextField } from '@mui/material'
import React from 'react'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { TieredMenu } from 'primereact/tieredmenu';
import Select from 'react-select';
import { InputSwitch } from 'primereact/inputswitch';
import { Dialog } from 'primereact/dialog';
import axios from 'axios';
import STORAGEKEY from "../../config/APP/app.config";
import AuthStorage from "../../helper/AuthStorage";
import { FileUpload, FileUploadHeaderTemplateOptions, FileUploadSelectEvent } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';

function BookingPartner() {

    const registerForm = {
        first_name: "",
        middle_name:"",
        last_name:"",
        firm_name: "",
        email: "",
        mobile: "",
        pan_no: "",
        postal_code: "",
        gst_no: null,
        state:'',
        city:'',
        gst_no_error: null,
        first_name_error: null,
        last_name_error: null,
        firm_name_error: null,
        email_error: null,
        mobile_error: null,
        pan_no_error: null,
        postal_code_error: null,
        city_error: null,
        state_error: null,
    }
    const [bookingPartnerDetails, setBookingPartnerDetails] = useState([]);
    const [loader, setLoader] = useState(false);
    const [addOpen, setAddOpen] = useState(false)
    const [isEdit, setIsEdit] = useState("")
    const [formData, setFormData] = useState<any>(registerForm);
    const [state, setState] = useState(STATE.DEFAULT_STATE);
    const [searchValue, setSearchValue] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 0,
        search: '',
        filters: {
          full_name: { value: null, matchMode: FilterMatchMode.IN },
          firm_name: { value: null, matchMode: FilterMatchMode.IN },
          city: { value: null, matchMode: FilterMatchMode.IN },
          state: { value: null, matchMode: FilterMatchMode.IN },
          kycstatus: { value: null, matchMode: FilterMatchMode.IN },
        }
    });
    const [hide, setHide] = useState<any>(true);
    const [Astate, setAState] = useState<any>("");
    const [city, setCity] = useState<any>("");
    const [kycPopUp, setKycPopUp] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [kycDetails, setkycDetails] = useState({});

    const [changeDoc,setChangeDoc]=useState(false)
    const [docName,setDocName]=useState('');
    const [fileUpload1Size, setFileUpload1Size] = useState(0);
    const fileUploadRef1 = useRef<FileUpload>(null);
    const [fileUpload1Files, setFileUpload1Files] = useState([]);
    const [kycForm,SetKycForm]=useState(null )

    const [cityOptions, setCityOptions] = useState(null);
    const [stateOptions, setStateOptions] = useState(null);
    const [firmNameOptions, setFirmNameOptions] = useState(null);
    const [fullNameOptions, setFullNameOptions] = useState(null);
    const [bookingpartnersOptions, setBookingpartnersOptions] =
      useState(null);

    const getBookingPartnerdata = async () => {
        setLoader(true);
        const modifiedLazyState = { ...lazyState, limit: lazyState.rows };
        delete modifiedLazyState.rows;
        delete modifiedLazyState['multiSortMeta'];
        delete modifiedLazyState['sortField'];
        delete modifiedLazyState['sortOrder'];

        // Function to replace empty arrays in the "value" field with null
        const replaceEmptyValueArrays = (obj) => {
            for (const key in obj) {
                if (key === 'value' && Array.isArray(obj[key]) && obj[key].length === 0) {
                    obj[key] = null;
                } else if (typeof obj[key] === 'object') {
                    replaceEmptyValueArrays(obj[key]);
                }
            }
            return obj;
        };

        
        // Call the function to replace empty arrays in the "value" field with null
        const finalModifiedLazyState = replaceEmptyValueArrays(modifiedLazyState);
        finalModifiedLazyState.page++;
        await ApiPost(`bookingpartner/get-booking-partner`, finalModifiedLazyState)
            .then((res: any) => {
                setTotalRecords(Number(res.data.count.count));
                let temp = res.data.result.map((item: any, index: number) => {
                    return {
                        id: item._id,
                        SRNO: (lazyState.first) + index + 1,
                        // Department: item.department,
                        full_name: item.full_name,
                        pan_no:item.pan_no,
                        mobile:item.mobile,
                        firm_name:item.firm_name,
                        first_name: item.first_name,
                        middle_name: item.middle_name,
                        last_name: item.last_name,    
                        email:item.email,
                        state:item.state ,  
                        city :item.city,
                        postal_code:item.postal_code,
                        kyc_id:item.kyc_id,
                        kycstatus: `${item.profile_completed ? "Completed" : "Pending"}`,

                        // reporting_id : item.reporting_id,
                        // Status:item.status,
                        // user_id:item.user_id
                    }
                })              
                      
                setBookingPartnerDetails(temp)
                setState({
                    ...state,
                    totalItems:res.data.count.count
                })
                
            }).catch((error) => {
            })
            setLoader(false);

    }

    useEffect(() => {
        Promise.all([
            getBookingPartnerdata(),
            getOptionsData()
        ])
          .then(() => {
            setTimeout(() => {
              setLoader(false);
            }, 250);
          })
          .catch((error) => {
            setLoader(false);
          });
      }, [lazyState]);
    const handleAddDataPopup = async () => {
        setAddOpen(true)
        setIsEdit("")
        setFormData(registerForm)
    }

    const onPage = (event) => {
        let filter = { ...lazyState };
        filter = event;
        filter['search'] = lazyState.search;
        setlazyState(filter);
    };

    const onFilter = (event) => {
        event['first'] = 0;
        event['page'] = 0;
        setlazyState(event);
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-end">
                <span>
                    <InputText
                        className="mr-2"
                        value={searchValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        placeholder="Keyword Search"
                    />
                    <Button icon="pi pi-search" onClick={handleSearchClick} />
                </span>
            </div>
        );
    };

    const onGlobalFilterChange = (e) => {
        const value = e;
        let filter = { ...lazyState };

        filter['search'] = value;

        setlazyState(filter);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onGlobalFilterChange(searchValue);
        }
    };

    const handleSearchClick = () => {
        onGlobalFilterChange(searchValue);
    };

    const handleInputChange = (event) => {
        setSearchValue(event.target.value);
    };

    const header = renderHeader();

    const menuRef = useRef(null);
    const selectedRowData = useRef(null);
    const handleEllipsisClick = (event, rowData) => {
        event.stopPropagation(); // Prevent event from bubbling up
        selectedRowData.current = rowData;
        if (menuRef.current) {
            menuRef.current.toggle(event);
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div>
                <img src="../assets/menu-cirlce.svg" alt="menu-icon" style={{ cursor: 'pointer' }} onClick={(e) => handleEllipsisClick(e, rowData)} />
                <TieredMenu model={generateMenuItems()} popup ref={menuRef} />
            </div>
        );
    };
    const generateMenuItems = () => {
        return [
            { label: 'Edit', command: () => handleMenuItemClick('Edit') },
            {
                label: "Download Kycs",
                command: () => handleMenuItemClick("Download Kycs"),
              }
        ];
    };

    const handleMenuItemClick = (itemLabel) => {
        if (itemLabel === 'Edit') {
            setAddOpen(true)
            setIsEdit(selectedRowData.current.id)
            // isEdit ?
            // editStaff(selectedRowData.current.id);
            let data = {
                first_name: selectedRowData.current.first_name,
                middle_name: selectedRowData.current.middle_name,
                last_name: selectedRowData.current.last_name,
                email: selectedRowData.current.email,
                address: selectedRowData.current.address,
                mobile: selectedRowData.current.mobile,
                pan_no: selectedRowData.current.pan_no,
                firm_name: selectedRowData.current.firm_name,
                state:selectedRowData.current.state,
                city : selectedRowData.current.city,
                postal_code:selectedRowData.current.postal_code,
                gst_no:selectedRowData.current.gst_no,

                // incentive_list: res?.data?.staff?.incentive_list,
                // salary_justification_ratio: res?.data?.staff?.salary_justification_ratio
            }
            setFormData(data)
            
            
        }
        else if (itemLabel === "Download Kycs") {
            console.log(selectedRowData.current)
            getKycById(selectedRowData.current.id);
            setSelectedUser(selectedRowData.current.kyc_id);
            setKycPopUp(true);
          }
    };

    const getKycById = async (id: any) => {


        await ApiGet(`kyc/get-kycdetails-byid/${id}`).then((res: any) => {
          if (res.success) {
            let temp = res.data.kycDetails;
            setkycDetails(temp);
          } else {
            toast.error(res.error, {
              position: "top-right",
              theme: "colored",
            });
          }
        }).catch((error)=>{
          //  console.log(error)
        });
      };
    const handlePostalCodeInput = (e) => {
        e.target.value = e.target.value.replace(/[^0-9]/g, '');
    };
    const handleNameInput = (e) => {
        e.target.value=e.target.value.replace(/[^A-Za-z\s]/g, '');
    };
  
    const signup = () => {
        let updatedForm = { ...formData };
        for (let key in updatedForm) {
            if(typeof updatedForm[key] == 'string'){
                updatedForm[key] = updatedForm[key].trim();
            }
        }
        let namePattern=/^[A-Za-z\s]+$/;
        let mobilePattern = /^\+?[0-9]{10,}$/;
        let emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/;
        let postalCodePattern=/^\d{6}$/;
        let panVal = formData.pan_no;

        //GST validation
        let gstVal = formData.gst_no;
        if (gstVal != null && gstVal.trim() != '' ) {
          if (gstVal.length != 15) {

          let statecode = gstVal.substring(0, 2);
        let patternstatecode = /^[0-9]{2}$/
        let threetoseven = gstVal.substring(2, 7);
        let patternthreetoseven = /^[a-zA-Z]{5}$/
        let seventoten = gstVal.substring(7, 11);
        let patternseventoten = /^[0-9]{4}$/
        let Twelveth = gstVal.substring(11, 12);
        let patternTwelveth = /^[a-zA-Z]{1}$/
        let Thirteen = gstVal.substring(12, 13);
        let patternThirteen = /^[1-9a-zA-Z]{1}$/
        let fourteen = gstVal.substring(13, 14);
        let patternfourteen = /^[zZ]{1}$/
        let fifteen = gstVal.substring(14, 15);
        let patternfifteen = /^[0-9a-zA-Z]{1}$/;
                
             if (!patternstatecode.test(statecode)) {
                updatedForm = {
                    ...updatedForm, gst_no_error: 'First two characters of GST No should be numbers'
                };
            } else if (!patternthreetoseven.test(threetoseven)) {
                updatedForm = {
                    ...updatedForm,
                    gst_no_error: 'Third to seventh characters of GST No should be alphabets'
                };
            } else if (!patternseventoten.test(seventoten)) {
                updatedForm = {
                    ...updatedForm,
                    gst_no_error: 'Eighth to Eleventh characters of GST No should be numbers'
                };
            } else if (!patternTwelveth.test(Twelveth)) {
                updatedForm = {
                    ...updatedForm,
                    gst_no_error: 'Twelveth character of GST No should be alphabet'
                };
            } else if (!patternThirteen.test(Thirteen)) {
                updatedForm = {
                    ...updatedForm,
                    gst_no_error: 'Thirteen characters of GST No can be either alphabet or numeric'
                };
            } else if (!patternfourteen.test(fourteen)) {
                updatedForm = {
                    ...updatedForm,
                    gst_no_error: 'Fourteen characters of GST No should be Z'
                };
            } else if (!patternfifteen.test(fifteen)) {
                updatedForm = {
                    ...updatedForm,
                    gst_no_error: 'Fifteen characters of GST No can be either alphabet or numeric'
                };
            } else if (!gstVal.toUpperCase().includes(panVal.toUpperCase())) {
                updatedForm = { ...updatedForm, gst_no_error: "Invalid GST No. Please check!" }
            } }
            else{
              updatedForm = {
                ...updatedForm,
                gst_no_error: 'Length should be restricted to 15 digits and should not allow anything more or less'
            };
            }
            
    }
    else (
      updatedForm = {
          ...updatedForm,
          gst_no_error: null
      })
        //PAN validation
        let firstFivePattern = /^[A-Za-z]{5}/;
        let firstFive = panVal.substring(0, 5);
        let sixtoNinePattern = /^[0-9]{4}/;
        let sixtoNine = panVal.substring(5, 9);
        let lastValPattern = /^[A-Za-z]{1}/;
        let lastVal = panVal.substring(9, 10);

        if (!formData.first_name ) {
            updatedForm = { ...updatedForm, first_name_error: "Please enter your first name" };
        }
        if (formData.first_name && !namePattern.test(formData.first_name)) {
            updatedForm = { ...updatedForm, first_name_error: "Please enter a valid name" };
        }
        // if (formData.middle_name == "") {
        //     updatedForm = { ...updatedForm, name_error: "Please enter a name" };
        // }
        if (!formData.last_name ) {
            updatedForm = { ...updatedForm, last_name_error: "Please enter your last name" };
        }
        if (formData.last_name && !namePattern.test(formData.last_name)) {
            updatedForm = { ...updatedForm, last_name_error: "Please enter a valid name" };
        }
        if (!formData.firm_name ) {
            updatedForm = { ...updatedForm, firm_name_error: "Please enter your firm name" };
        }
        if (formData.firm_name && !namePattern.test(formData.firm_name)) {
            updatedForm = { ...updatedForm, firm_name_error: "Please enter a valid name" };
        }
        if (!formData.email ) {
            updatedForm = { ...updatedForm, email_error: "Please enter your email" };
        }
        if (formData.email && !emailPattern.test(formData.email)) {
            updatedForm = { ...updatedForm, email_error: "Please enter a valid email" };
        }
        
        if (!formData.mobile ) {
            updatedForm = { ...updatedForm, mobile_error: "Please enter your mobile no." };
        }
        if (formData.mobile && !mobilePattern.test(formData.mobile)) {
            updatedForm = { ...updatedForm, mobile_error: "Please enter valid mobile no." };
        }
        if (!formData.postal_code ) {
            updatedForm = { ...updatedForm, postal_code_error: "Please enter postal code" };
        }
        if (formData.postal_code && !postalCodePattern.test(formData.postal_code)) {
            updatedForm = { ...updatedForm, postal_code_error: "Please enter a valid postal code" };
        }

        if (!formData.pan_no ) {
            updatedForm = { ...updatedForm, pan_no_error: "Please enter your pan no" };
        } else if (panVal.length != 10) {
            updatedForm = {
                ...updatedForm,
                pan_no_error: 'Length should be restricted to 10 digits and should not allow anything more or less'
            };
        } else if (!firstFivePattern.test(firstFive)) {
            updatedForm = {
                ...updatedForm, pan_no_error: 'First Five characters of PAN No should be alphabets'
            };
        } else if (!sixtoNinePattern.test(sixtoNine)) {
            updatedForm = {
                ...updatedForm, pan_no_error: 'Six to Nine characters of PAN No should be number'
            };
        } else if (!lastValPattern.test(lastVal)) {
            updatedForm = {
                ...updatedForm,
                pan_no_error: 'Last characters of PAN No should be alphabets'
            };
        } else (
            updatedForm = {
                ...updatedForm,
                pan_no_error: null
            })

        
        // if (state == "") {
        //     updatedForm = { ...updatedForm, state_error: "Please enter a state" };
        // }
        // if (city == "") {
        //     updatedForm = { ...updatedForm, city_error: "Please enter a city" };
        // }
        setFormData(updatedForm)
        if (updatedForm.first_name_error == null &&
            updatedForm.last_name_error == null &&
            updatedForm.firm_name_error == null &&
            updatedForm.email_error == null &&
            updatedForm.mobile_error == null &&
            updatedForm.pan_no_error == null &&
            //updatedForm.state_error == null &&
            //updatedForm.city_error == null &&
            updatedForm.postal_code_error == null) {
            const {  first_name_error,last_name_error,
                firm_name_error,
                email_error,               
                mobile_error,
                pan_no_error,
                postal_code_error,
                // city_error, state_error,
                  ...payload } = updatedForm;
            
            const body = {
                ...payload ,gst_no:(updatedForm.gst_no == "" || updatedForm.gst_no == " ") ? null : updatedForm.gst_no               
            }
           if(isEdit == ''){ ApiPost("bookingpartner/register", body)
                .then((res: any) => {
                    getBookingPartnerdata()
                    setFormData(registerForm)
                    setAState("")
                    setCity("")
                    setAddOpen(false)
                    if(res.status == 200){
                        toast.success('Booking Partner Added successfully' , {
                                position: "top-right",
                                theme: "colored"
                              })
                    }
                })

                .catch((err) => {
                    let error = formData;
                    // toast.error(err?.error , {
                    //     position: "top-right",
                    //     theme: "colored"
                    //   })
                    if (err?.error == "Incorrect referral codes") {
                        error = { ...error, invite_code_error: "Please enter a correct referral code" };
                    }
                    if (err?.error == "User with email already exists") {
                        error = { ...error, email_error: "email already exists" };
                    }
                    setFormData({ ...formData, ...error })
                })}
                else{
                    ApiPut(`bookingpartner/edit-booking-partner?id=${isEdit}`, body)
                .then((res: any) => {
                    getBookingPartnerdata()
                    setFormData(registerForm)
                    setAState("")
                    setCity("")
                    setAddOpen(false)
                    if(res.status == 200){
                        toast.success('Booking Partner Added successfully' , {
                                position: "top-right",
                                theme: "colored"
                              })
                    }
                })

                .catch((err) => {
                    let error = formData;
                    // toast.error(err?.error , {
                    //     position: "top-right",
                    //     theme: "colored"
                    //   })
                    if (err?.error == "Incorrect referral codes") {
                        error = { ...error, invite_code_error: "Please enter a correct referral code" };
                    }
                    if (err?.error == "User with email already exists") {
                        error = { ...error, email_error: "email already exists" };
                    }
                    setFormData({ ...formData, ...error })
                })
                }
        }
    }

    const getDowloadLink = async (path: any) => {
        if (path != undefined) {
          await ApiPost(`kyc/get-image-dowload-link`, {
            path,
          }).then((res: any) => {
            const linkElement = document.createElement("a");
            linkElement.href = res.data.url;
            linkElement.click();
          }).catch((error)=>{
            //  console.log(error)
          });
        } else {
          toast.error("Doc not uploaded", {
            position: "top-right",
            theme: "colored",
          });
        }
      };

      const onSelect = (e: FileUploadSelectEvent, setTotalSize, setFiles, link) => {
        let totalSize = 0;
        let files = e.files;
    
        for (let i = 0; i < files.length; i++) {
          totalSize += files[i].size || 0;
        }
    
        setTotalSize(totalSize);
        setFiles(files);
      };
      const onClear = (setTotalSize) => {
        setTotalSize(0);
      };
      const onRemove = (e, totalSize, setTotalSize) => {
        setTotalSize(totalSize - (e.file.size || 0));
      };
      const headerTemplate =
        (totalSize) => (options: FileUploadHeaderTemplateOptions) => {
          const { className, chooseButton } = options;
          const value = totalSize / 10000;
          const formattedValue = totalSize ? `${totalSize / 1000} KB` : "0 B";
    
          return (
            <div
              className={className}
              style={{
                backgroundColor: "transparent",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  flex: "1",
                }}
              >
                {chooseButton}
              </div>
              <div className="progress-container">
                <span>{formattedValue} / 1 MB</span>
                <ProgressBar
                  value={value}
                  showValue={false}
                  className="custom-progress-bar"
                ></ProgressBar>
              </div>
            </div>
          );
        };
    
      const itemTemplate = (file, props) => {
        return (
          <div className="flex align-items-center flex-wrap">
            <div className="flex align-items-center" style={{ width: "40%" }}>
              <span>{file.name}</span>
            </div>
            <Button
              type="button"
              raised
              severity="info"
              icon="pi pi-times"
              className="p-button-outlined p-button-rounded p-button-danger ml-auto"
              onClick={() => props.onRemove(file)}
            />
          </div>
        );
      };
    
      const changeDocument=async ()=>{
        if (docName === "pancard") {
          if (fileUploadRef1.current.getFiles().length != 0) {
            const form_data = new FormData();
            form_data.append("pan_card_image", kycForm.file);
            if (kycForm.file != null) {
              await ApiPut(`kyc/update?id=${selectedUser}`, form_data)
                .then(async (response: any) => {
                  if (response) {
                    toast.success(" Pan card updated Successfully", {
                      position: "top-right",
                      theme: "colored",
                    });
                  }
                  setChangeDoc(false);
                  // setFormData({ formData });
                  fileUploadRef1.current.clear();
                })
                .catch((error: any) => console.log("Error", error));
            }
          } else {
            toast.error("Please Upload Document", {
              position: "top-right",
              theme: "colored",
            });
          }
        }
        if (docName === "bank") {
          if (fileUploadRef1.current.getFiles().length != 0) {
            const form_data = new FormData();
            form_data.append("cheque_image", kycForm.file);
            if (kycForm.file != null) {
              await ApiPut(`kyc/update?id=${selectedUser}`, form_data)
                .then(async (response: any) => {
                  if (response) {
                    toast.success(" Bank detail updated Successfully", {
                      position: "top-right",
                      theme: "colored",
                    });
                  }
                  setChangeDoc(false);
                  // setFormData({ formData });
                  fileUploadRef1.current.clear();
                })
                .catch((error: any) => console.log("Error", error));
            }
          } else {
            toast.error("Please Upload Document", {
              position: "top-right",
              theme: "colored",
            });
          }
        }
        if (docName === "gst") {
          if (fileUploadRef1.current.getFiles().length != 0) {
            const form_data = new FormData();
            form_data.append("gst_image", kycForm.file);
            if (kycForm.file != null) {
              await ApiPut(`kyc/update?id=${selectedUser}`, form_data)
                .then(async (response: any) => {
                  if (response) {
                    toast.success(" Gst updated Successfully", {
                      position: "top-right",
                      theme: "colored",
                    });
                  }
                  setChangeDoc(false);
                  // setFormData({ formData });
                  fileUploadRef1.current.clear();
                })
                .catch((error: any) => console.log("Error", error));
            }
          } else {
            toast.error("Please Upload Document", {
              position: "top-right",
              theme: "colored",
            });
          }
        }
      }
      const convertToObjectArray = (obj) => {
        return Object.keys(obj).map((key) => ({
          label: obj[key],
          value: key,
        }));
      };
      const getOptionsData = () => {
        setLoader(true);
        ApiGet(`options/bookingPartner`).then((res: any) => {

         const cities = convertToObjectArray(res.data.city);
          const states = convertToObjectArray(res.data.state);
          const firmNames = convertToObjectArray(res.data.firm_name);
          const bookingpartners = convertToObjectArray(
            res.data.booking_parnter
          );   

          setCityOptions(cities);
          setStateOptions(states);
          setFirmNameOptions(firmNames);
          setBookingpartnersOptions(bookingpartners);
        }).catch((error)=>{
          //  console.log(error)
        });
      };
      const NameRowFilterTemplate = (options) => {
        return (
          <MultiSelect
            value={options.value}
            options={bookingpartnersOptions}
            onChange={(e) => options.filterCallback(e.value)}
            optionLabel="label"
            placeholder="Select"
            className="p-column-filter"
            maxSelectedLabels={1}
            style={{ minWidth: "14rem" }}
            filter
            filterPlaceholder="Search"
          />
        );
      };
    
      
    
      const firmNameRowFilterTemplate = (options) => {
        return (
          <MultiSelect
            value={options.value}
            options={firmNameOptions}
            onChange={(e) => options.filterCallback(e.value)}
            optionLabel="label"
            placeholder="Select"
            className="p-column-filter"
            maxSelectedLabels={1}
            style={{ minWidth: "14rem" }}
            filter
            filterPlaceholder="Search"
          />
        );
      };
    
      const cityRowFilterTemplate = (options) => {
        return (
          <MultiSelect
            value={options.value}
            options={cityOptions}
            onChange={(e) => options.filterCallback(e.value)}
            optionLabel="label"
            placeholder="Select"
            className="p-column-filter"
            maxSelectedLabels={1}
            style={{ minWidth: "14rem" }}
            filter
            filterPlaceholder="Search"
          />
        );
      };
    
      const stateRowFilterTemplate = (options) => {
        return (
          <MultiSelect
            value={options.value}
            options={stateOptions}
            onChange={(e) => options.filterCallback(e.value)}
            optionLabel="label"
            placeholder="Select"
            className="p-column-filter"
            maxSelectedLabels={1}
            style={{ minWidth: "14rem" }}
            filter
            filterPlaceholder="Search"
          />
        );
      };

      const [kycStatus] = useState([
        { label: "Pending", value: "Pending" },
        { label: "Completed", value: "Completed" },
      ]);
    
      const kycRowFilterTemplate = (options) => {
        return (
          <MultiSelect
            value={options.value}
            options={kycStatus}
            onChange={(e) => options.filterCallback(e.value)}
            optionLabel="label"
            placeholder="Select"
            className="p-column-filter"
            maxSelectedLabels={1}
            style={{ minWidth: "14rem" }}
            filter
            filterPlaceholder="Search"
          />
        );
      };
    // const postget = async (e: any) => {

    //   const value = e.target.value;
    //     setFormData({ ...formData, [e.target.name]: e.target.value, postal_code_error: null, state_error: null, city_error: null })
    //     if (value.length >= 6 ) {
    //         const options = {
    //             method: 'GET',
    //             url: `https://india-pincode-with-latitude-and-longitude.p.rapidapi.com/api/v1/pincode/${value}`,
    //             headers: {
    //               'X-RapidAPI-Key': 'b2572becadmshbaf647a375178edp179823jsneae8fa2a8334',
    //               'X-RapidAPI-Host': 'india-pincode-with-latitude-and-longitude.p.rapidapi.com'
    //             }
    //           };
    //         await axios.request(options)
    //         .then((res: any) => {
    //             setFormData({ ...formData,
    //                 state: res?.data[0].state,
    //                 city: res.data[0].district,
    //                 postal_code: value, })
    //             // setAState(res?.data[0]?.state);
    //             // setCity(res?.data[0]?.district);
    //         })
    //         // await axios.get(`https://api.postalpincode.in/pincode/${value}`)
    //         //     .then((res: any) => {
    //         //         setState(res?.data[0]?.PostOffice[0]?.State);
    //         //         setCity(res?.data[0]?.PostOffice[0]?.District);
    //         //     })
    //     }
    //   // const value = e.target.value;

    //   // setFormData({ ...formData, [e.target.name]: e.target.value, postal_code_error: null, state_error: null, city_error: null })
    //   // if (value.length >= 6 )
    //   // ApiGet(`auth/get-post?pincode=${e.target.value}`)
    //   // .then((res: any) => {
  
    //   //   setFormData({ ...formData,
    //   //     state: res.data?.data?.state ?? '',
    //   //     city: res.data?.data?.city ?? '',
    //   //     postal_code: e.target.value, })
    //   // })
        
    // }

    const postget = async (e: any) => {
      const value = e.target.value;
      setFormData({ ...formData, [e.target.name]: e.target.value, postal_code_error: null, state_error: null, city_error: null })
      if (value.length >= 6 ) {
          const options = {
              method: 'GET',
              url: `https://india-pincode-with-latitude-and-longitude.p.rapidapi.com/api/v1/pincode/${value}`,
              headers: {
                'X-RapidAPI-Key': 'b2572becadmshbaf647a375178edp179823jsneae8fa2a8334',
                'X-RapidAPI-Host': 'india-pincode-with-latitude-and-longitude.p.rapidapi.com'
              }
            };
          await axios.request(options)
          .then((res: any) => {
              setFormData({ ...formData,
                  state: res?.data[0].state,
                  city: res.data[0].district,
                  postal_code: value, })
              // setAState(res?.data[0]?.state);
              // setCity(res?.data[0]?.district);
          })
          // await axios.get(`https://api.postalpincode.in/pincode/${value}`)
          //     .then((res: any) => {
          //         setState(res?.data[0]?.PostOffice[0]?.State);
          //         setCity(res?.data[0]?.PostOffice[0]?.District);
          //     })
      }
  }


    return (<div className="overflow-x-hidden p-6">
        {/* <Sidebar />
    <Header /> */}
        {loader ?
            <>
                {/* START::OVERLAY */}
                <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
                    <img src="assets/logo.svg" />
                </div>
                {/* END::OVERLAY */}
            </>
            :
            <>
<div className="p-4 sm:p-9 space-y-5">

{/* START::DASHBOARD HEADER */}
<div className="flex flex-wrap gap-5 items-center justify-between">
    <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9 ">
    <h1 className="text-[22px] text-light-gray font-semibold">
    Booking Partner                </h1>
    </div>

    {/* START::EXPORT */}
    <ul className="flex space-x-[18px]">
        <li>
            <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold bg-primary text-white" onClick={() => handleAddDataPopup()}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
                </svg>
                <span>Add Booking Partner</span>
            </button>
        </li>
    </ul>
    {/* END::EXPORT */}
</div>
{/* END::DASHBOARD HEADER */}
<DataTable
                    value={bookingPartnerDetails}
                    lazy
                    dataKey="id"
                    showGridlines
                    paginator
                    first={lazyState.first}
                    filters={lazyState.filters}
                    onFilter={onFilter}
                    rows={lazyState.rows}
                    rowsPerPageOptions={[10, 20, 30, 40, 50]}
                    totalRecords={totalRecords}
                    onPage={onPage}
                    loading={loader}
                    size={'small'}
                    scrollable
                    scrollHeight="600px"
                    className="custom-datatable"
                    header={header}
                    emptyMessage="No records found."
                >
                    <Column
                        field="SRNO"
                        frozen
                        header="SR.NO."
                    />
                    <Column
                        field="full_name"
                        header="Name"
                        showFilterMatchModes={false}
              filterMenuStyle={{ minWidth: "14rem" }}
              filter
              filterElement={NameRowFilterTemplate}
                    />
                    <Column
                        field="firm_name"
                        header="Firm Name"
                        showFilterMatchModes={false}
              filterMenuStyle={{ minWidth: "14rem" }}
              filter
              filterElement={firmNameRowFilterTemplate}
                    />
                    <Column
                        field="city"
                        header="City"
                        showFilterMatchModes={false}
              filterMenuStyle={{ minWidth: "14rem" }}
              filter
              filterElement={cityRowFilterTemplate}
                    />
                    <Column
                        field="state"
                        header="State"
                        showFilterMatchModes={false}
              filterMenuStyle={{ minWidth: "14rem" }}
              filter
              filterElement={stateRowFilterTemplate}
                    /> 
                    <Column
                        field="email"
                        header="email"
                    />   
                    <Column
                        field="mobile"
                        header="mobile"
                    />  
                    <Column
                        field="kycstatus"
                        header="Kyc Status"
                        showFilterMatchModes={false}
              filterMenuStyle={{ minWidth: "14rem" }}
              filter
              filterElement={kycRowFilterTemplate}
                    />                            
                    <Column
                        field="Action"
                        header="Action"
                        body={actionBodyTemplate}
                    />
                </DataTable>
                <div className={`tw-modal ${addOpen ? 'flex' : 'hidden'} !items-start`}>
                    <div className="tw-modal-content">
                        <div className="tw-modal-title">
                            <div>{isEdit ? "Edit Staff Data" : "Add Staff Data"}</div>
                            <button onClick={() => {setAddOpen(false)
                                setFormData(registerForm)
                            }}>
                                <img src="assets/close-outline.svg" alt="" />
                            </button>
                        </div>

                        <div className='tw-modal-body'>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                            <div className="space-y-1">
                                    <label htmlFor="first_name" className={`input-label ${formData.first_name_error ? "text-red-500" : ""}`}>First Name<span style={{color:'red'}}>*</span></label>
                                    <div id="first_name" className={`input-wrap ${formData.first_name_error ? "border-red-500" : ""}`}>
                                        <img src="assets/user.svg" alt="" />
                                        <input type="text" name="first_name" value={formData.first_name} onInput={handleNameInput} onChange={(e: any) => {
                                            setFormData({ ...formData, first_name: e.target.value, first_name_error: null })
                                        }} className="w-full text-sm" placeholder="Enter your First name" />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.first_name_error}</p>
                                </div>

                                <div className="space-y-1">
                                    <label htmlFor="middle_name" className="input-label">Middle Name</label>
                                    <div id="middle_name" className="input-wrap " >
                                        <img src="assets/user.svg" alt="" />
                                        <input type="text" name="middle_name" value={formData.middle_name} onInput={handleNameInput} onChange={(e: any) => {
                                            setFormData({ ...formData, middle_name: e.target.value })
                                        }} className="w-full text-sm" placeholder="Enter your Middle name" />
                                    </div>
                                    {/* <p className="text-red-500 text-sm">{formData.name_error}</p> */}
                                </div>

                                <div className="space-y-1">
                                    <label htmlFor="last_name" className={`input-label ${formData.last_name_error ? "text-red-500" : ""}`}>Last Name<span style={{color:'red'}}>*</span></label>
                                    <div id="last_name" className={`input-wrap ${formData.last_name_error ? "border-red-500" : ""}`}>
                                        <img src="assets/user.svg" alt="" />
                                        <input type="text" name="last_name" value={formData.last_name} onInput={handleNameInput} onChange={(e: any) => {
                                            setFormData({ ...formData, last_name: e.target.value, last_name_error: null })
                                        }} className="w-full text-sm" placeholder="Enter your Last name" />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.last_name_error}</p>
                                </div>
                                
                                <div className="space-y-1">
                                    <label htmlFor="firm_name" className={`input-label ${formData.firm_name_error ? "text-red-500" : ""}`}>Firm Name<span style={{color:'red'}}>*</span></label>
                                    <div id="firm_name" className={`input-wrap ${formData.firm_name_error ? "border-red-500" : ""}`}>
                                        <img src="assets/firm.svg" alt="" />
                                        <input type="text" name="firm_name" value={formData.firm_name} onInput={handleNameInput} onChange={(e: any) => {
                                            setFormData({ ...formData, firm_name: e.target.value, firm_name_error: null })
                                        }} className="w-full text-sm" placeholder="Enter your Firm name" />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.firm_name_error}</p>
                                </div>                                
                                <div className="space-y-1">
                                    <label htmlFor="email" className={`input-label ${formData.email_error ? "text-red-500" : ""}`}>Email<span style={{color:'red'}}>*</span></label>
                                    <div id="email" className={`input-wrap ${formData.email_error ? "border-red-500" : ""}`}>
                                        <img src="assets/email.svg" alt="" />
                                        <input type="text" name="email" value={formData.email} onChange={(e: any) => {
                                            setFormData({ ...formData, email: e.target.value, email_error: null })
                                        }} className="w-full text-sm" placeholder="johndue@xyz.com" disabled={isEdit != '' ? true : false} />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.email_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="mobile" className={`input-label ${formData.mobile_error ? "text-red-500" : ""}`}>Mobile No.<span style={{color:'red'}}>*</span></label>
                                    <div id="mobile" className={`input-wrap ${formData.mobile_error ? "border-red-500" : ""}`}>
                                        <img src="assets/phone.svg" alt="" />
                                        <input type="text" name="mobile" value={formData.mobile} maxLength={13} minLength={10} onChange={(e: any) => {
                                            setFormData({ ...formData, mobile: e.target.value, mobile_error: null })
                                        }} className="w-full text-sm" placeholder="Enter Mobile No." />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.mobile_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="pan_no" className={`input-label ${formData.pan_no_error ? "text-red-500" : ""}`}>PAN No.<span style={{color:'red'}}>*</span></label>
                                    <div id="pan_no" className={`input-wrap ${formData.pan_no_error ? "border-red-500" : ""}`}>
                                        <img src="assets/card.svg" alt="" />
                                        <input type="text" name="pan_no" maxLength={10} value={formData.pan_no} onChange={(e: any) => {
                                            setFormData({ ...formData, pan_no: e.target.value, pan_no_error: null })
                                        }} className="w-full text-sm uppercase" placeholder="Enter PAN No." />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.pan_no_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="postal_code" className={`input-label ${formData.postal_code_error ? "text-red-500" : ""}`}>Postal Code (Office)<span style={{color:'red'}}>*</span></label>
                                    <div id="postal_code" className={`input-wrap ${formData.postal_code_error ? "border-red-500" : ""}`}>
                                        <img src="assets/location.svg" alt="" />
                                        <input type="text" name="postal_code" maxLength={6} onInput={handlePostalCodeInput} value={formData.postal_code} onChange={(e: any) => { postget(e) }} className="w-full text-sm" placeholder="Enter Postal Code" />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.postal_code_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="city" className='input-label'>City</label>
                                    <div id="city" className='input-wrap'>
                                        <img src="assets/location.svg" alt="" />
                                        <input type="text" name="city" onChange={(e: any) => {
                                            setFormData({ ...formData,city:e.target.value })
                                            // setCity(e.target.value)
                                        }} value={formData.city} className="w-full text-sm" placeholder="Enter City" disabled/>
                                    </div>
                                    {/* <p className="text-red-500 text-sm">{formData.city_error}</p> */}
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="state" className='input-label'>State</label>
                                    <div id="state" className='input-wrap'>
                                        <img src="assets/location.svg" alt="" />
                                        <input type="text" name="state" onChange={(e: any) => {
                                            setFormData({ ...formData,state:e.target.value })
                                            // setState(e.target.value);
                                        }} value={formData.state} className="w-full text-sm" placeholder="Enter State" disabled/>
                                    </div>
                                    {/* <p className="text-red-500 text-sm">{formData.state_error}</p> */}
                                </div>                                
                                <div className="space-y-1">
                                    <label htmlFor="gst" className={`input-label ${formData.gst_no_error ? "border-red-500" : ""}`}>GST No.</label>
                                    <div id="gst_no" className={`input-wrap ${formData.gst_no_error ? "border-red-500" : ""}`}>
                                        <img src="assets/card.svg" alt="" />
                                        <input type="text" name="gst_no" maxLength={15} value={formData.gst_no} onChange={(e: any) => {
                                            setFormData({ ...formData, gst_no: e.target.value , gst_no_error: null})
                                        }} className="w-full text-sm uppercase" placeholder="Enter GST No." />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.gst_no_error}</p>
                                </div>


                            </div>


                        </div>

                        <div className='tw-modal-footer'>
                            <button className='tw-modal-footer-button bg-secondary text-white' onClick={()=>signup()}>Save</button>
                            <button className='tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary' onClick={() => {setAddOpen(false)
                                setFormData(registerForm)
                            }}>Cancel</button>
                        </div>
                    </div>
                </div>

                <div
            className={`tw-modal ${kycPopUp ? "flex" : "hidden"} !items-start`}
          >
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                <div>KYC Admin</div>
                <button onClick={() => setKycPopUp(false)}>
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>

              <div className="tw-modal-body">
                <div className="space-y-1">
                  <div className="md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0">
                    <div className="w-full space-y-1 flex justify-between">
                    <button
                      className="px-16 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary"
                      onClick={() => {
                        getDowloadLink(kycDetails["pan_card_image"]);
                      }}
                    >
                      Download PanCard
                    </button>
                    <button
                      className="px-16 mt-2 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary"
                      onClick={() => {
                        getDowloadLink(kycDetails["cheque_image"]);
                      }}
                    >
                      Download Cheque
                    </button>
                    <button
                      className="px-16 mt-2 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary"
                      onClick={() => {
                        getDowloadLink(kycDetails["gst_image"]);
                      }}
                    >
                      Download GST
                    </button>
                  </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0">
                    <div className="w-full space-y-1 flex justify-between">
                    <button
                      className="px-16 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary"
                      onClick={() => {
                        setChangeDoc(true)
                        setDocName('pancard')
                      }}
                    >
                      Change PanCard
                    </button>
                    <button
                      className="px-16 mt-2 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary"
                      onClick={() => {
                        setDocName('bank')
                        setChangeDoc(true)
                      }}
                    >
                      Change Cheque
                    </button>
                    <button
                      className="px-16 mt-2 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary"
                      onClick={() => {
                        setDocName('gst')
                        setChangeDoc(true)
                      }}
                    >
                      Change GST
                    </button>
                  </div>
                  </div>
                </div>
              </div>
              <div className="tw-modal-footer"></div>
            </div>
          </div>
          <div
            className={`tw-modal ${changeDoc ? "flex" : "hidden"}`}
          >
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                Change {docName.toLocaleUpperCase()} Document 
                <button
                  onClick={() => {
                    setChangeDoc(false);
                    fileUploadRef1.current.clear();
                  }}
                >
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>
              <div className={`tw-modal-body `}>
                <div
                  
                >
                  <FileUpload
                    // mode="basic"
                    ref={fileUploadRef1}
                    name="demo[]"
                    accept=".jpg,.jpeg,.png,.gif,.pdf"
                    maxFileSize={1000000}
                    // onUpload={(e) => onUpload(e, setFileUpload1Size)}
                    onSelect={(e) => {
                      onSelect(
                        e,
                        setFileUpload1Size,
                        setFileUpload1Files,
                        "pan"
                      );
                      SetKycForm({
                        ...kycForm,
                        file: e.files[0],
                        file_error: null,
                      });
                    }}
                    onError={() => onClear(setFileUpload1Size)}
                    onClear={() => onClear(setFileUpload1Size)}
                    onRemove={(e) =>
                      onRemove(e, fileUpload1Size, setFileUpload1Size)
                    }
                    headerTemplate={headerTemplate(fileUpload1Size)}
                    itemTemplate={itemTemplate}
                    emptyTemplate={
                      <p className="m-0">
                        Drag and drop files to here to upload.
                      </p>
                    }
                  />
                </div>
                
              </div>
              <div className="tw-modal-footer">
                <Button
                  icon="pi pi-check"
                  raised
                  severity="info"
                  onClick={() => {
                    changeDocument();
                  }}
                >
                  <span style={{ marginLeft: "8px" }}> Save</span>
                </Button>
                <Button
                  icon="pi pi-times"
                  raised
                  severity="danger"
                  onClick={() => {
                    fileUploadRef1.current.clear();
                    setChangeDoc(false)
                  }}
                >
                  <span style={{ marginLeft: "8px" }}> Cancel</span>
                </Button>
              </div>
            </div>
          </div>
          </div>

    </>
}
</div >)
}

export default BookingPartner