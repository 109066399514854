
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { PERMISSION_TYPE, userPermission } from "../../helper/permission";
import { RootState } from "../../redux/store";
import { toast } from "react-toastify";
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { TieredMenu } from "primereact/tieredmenu";
import { ConfirmDialog } from "primereact/confirmdialog";
import 'jspdf-autotable';
import { format, parseISO } from 'date-fns';
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { FileUpload, FileUploadHeaderTemplateOptions, FileUploadSelectEvent, FileUploadUploadEvent } from "primereact/fileupload";
import { ProgressBar } from "primereact/progressbar";

const InvoiceAdminData = () => {
  
  
  const [loader, setLoader] = useState(true);

    const [permission, setPermission] = useState<any>({});
  const userData = useSelector(
    (state: RootState) => state?.authUser?.userData?.user
  );
  const [searchValue, setSearchValue] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [userNameOptions, setUserNameOptions] = useState(null);

  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 0,
    pagination:'',
    search: "",
    filters: {
      Created_at:{ value: null, matchMode: FilterMatchMode.IN },
      user_name: { value: null, matchMode: FilterMatchMode.IN },
      status:{ value: null, matchMode: FilterMatchMode.IN }
    },
  });
    const [selectedId, setSelectedId] = useState<string>("");
  
  const [invoiceDetail, setInvoiceDetail] = useState([]);    
   const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [invoiceStatusOptions, setInvoiceStatusOptions] =
  useState(null);
    const [viewInvoice,setViewInvoice]=useState(false)
    const [viewInvoiceProof,setViewInvoiceProof]=useState(false)

    const [invoicePath,setInvoicePath]=useState('')
    const [invoicePathLink,setInvoicePathLink]=useState('')

    const [veiwCases, setViewCases] =
    useState<boolean>(false);
        const [invoiceCases, setInvoiceCases] = useState([]);
        const [rejectPopup, setRejectPopup] = useState<boolean>(false);
        const [viewRejectReasonPopup, setViewRejectReasonPopup] = useState<boolean>(false);

        const reject_data = {
          comment: "",
          error:null
        };
        const [invoiceRejectData, setInvoiceRejectData] =
        useState<any>(reject_data);
    const [uploadProofPopup,setUploadProofPopup]=useState(false);
    const [proofUploadSize, setProofUploadSize] = useState(0);
    const proofUploadRef = useRef<FileUpload>(null);
    const [proofUploadFiles, setProofUploadFiles] = useState([]);
    const [invoiceProofFormData , setInvoiceProofFormData]=useState({
      file:null,
      file_error:null,
      file_link:''
    });
  let date=new Date().toDateString();
    useEffect(() => {
    
    const tempPermission = {
      [PERMISSION_TYPE.ADMIN]: userPermission(userData, PERMISSION_TYPE.ADMIN),
      [PERMISSION_TYPE.USER]: userPermission(userData, PERMISSION_TYPE.USER),
      [PERMISSION_TYPE.STAFF]: userPermission(userData, PERMISSION_TYPE.STAFF),
      [PERMISSION_TYPE.CONNECTOR]: userPermission(userData, PERMISSION_TYPE.CONNECTOR),
      [PERMISSION_TYPE.STAFF_OPS]: userPermission(userData, PERMISSION_TYPE.STAFF_OPS),
      [PERMISSION_TYPE.BOOKING_PARTNER]: userPermission(userData, PERMISSION_TYPE.BOOKING_PARTNER),

    };
    setPermission(tempPermission);
  }, [userData]);

  const convertToObjectArray = (obj) => {
    return Object.keys(obj).map((key) => ({
      label: obj[key],
      value: key,
    }));
  };

      const onPage = (event) => {
    let filter = { ...lazyState };
    filter = event;
    filter["search"] = lazyState.search;
    setlazyState(filter);
  };
  const onFilter = (event) => {
    event['first'] = 0;
    event['page'] = 0;
    const dismonthValue = event.filters?.Created_at?.value;
    if (
      dismonthValue &&
      dismonthValue.month === null &&
      dismonthValue.year === null
    ) {
      event.filters.Created_at.value = null;
    }

    setlazyState(event);
};

const getInvoicedata = async () => {
  setLoader(true);
  const modifiedLazyState = { ...lazyState, limit: lazyState.rows };
  delete modifiedLazyState.rows;
  delete modifiedLazyState['multiSortMeta'];
  delete modifiedLazyState['sortField'];
  delete modifiedLazyState['sortOrder'];

  // Function to replace empty arrays in the "value" field with null
  const replaceEmptyValueArrays = (obj) => {
      for (const key in obj) {
          if (key === 'value' && Array.isArray(obj[key]) && obj[key].length === 0) {
              obj[key] = null;
          } else if (typeof obj[key] === 'object') {
              replaceEmptyValueArrays(obj[key]);
          }
      }
      return obj;
  };

  // Call the function to replace empty arrays in the "value" field with null
  const finalModifiedLazyState = replaceEmptyValueArrays(modifiedLazyState);
  finalModifiedLazyState.page++;
  await ApiPost(`invoiceDetails/get-invoice-list`, finalModifiedLazyState)
      .then((res: any) => {
        setTotalRecords(Number(res.data.count[0].count));
          let temp = res.data.result.map((item: any, index: number) => {
              return {
                  id: item._id,
                  SRNO: (lazyState.first) + index + 1,
                  Created_at:format(new Date(item?.created_at), 'dd-MM-yyyy hh:mm:ss') ?? "-",
                  ...item
              }
          })
          setInvoiceDetail(temp)
      }).catch((error) => {
          setInvoiceDetail([])
      })
      setLoader(false)
}

  
const getUserOptionsData = () => {
  setLoader(true);
  ApiGet(`user/option-users`).then((res: any) => {
    const fullNames = convertToObjectArray(res.data.users);
    setUserNameOptions(fullNames);
  }).catch((error)=>{
    //  console.log(error)
  });
};

const getOptionsData = () => {
  setLoader(true);
  ApiGet(`options/invoice`).then((res: any) => {
    const invoiceStatusOptions = convertToObjectArray(res.data.invoice_status);

    setInvoiceStatusOptions(invoiceStatusOptions);
  }).catch((error)=>{
    //  console.log(error)
  });
};
  const renderHeader = () => {
    return (
      <div className="flex justify-end justify-space-between">
          <span>
            <InputText
              className="mr-2 ml-3"
              value={searchValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder="Keyword Search"
            />
            <Button icon="pi pi-search" onClick={handleSearchClick} />
          </span>
      </div>
    );
  };

  
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onGlobalFilterChange(searchValue);
    }
  };

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };
  const handleSearchClick = () => {
    onGlobalFilterChange(searchValue);
  };
  const onGlobalFilterChange = (e) => {
    const value = e;
    let filter = { ...lazyState };

    filter["search"] = value;

    setlazyState(filter);
  };

  const header = renderHeader();

  const menuRef = useRef(null);
  const selectedRowData = useRef(null);
  
  const [approveInvoiceVisible, setApproveInvoiceVisible] =
    useState<boolean>(false);
  const approveInvoice = () => {

    const form_data = new FormData();
    form_data.append("invoice_proof_doc",invoiceProofFormData.file);
    form_data.append("id",selectedRowData.current.id);
    form_data.append("type",'approve');

    try {
      ApiPut(`invoiceDetails/update-invoice-status`,form_data).then(
        (res: any) => {
          getInvoicedata()
          toast.success(res.data, {
            position: "top-right",
            theme: "colored",
          });
        }
      );
    } catch (error) {
      // toast.error(error.error, {
      //   position: "top-right",
      //   theme: "colored"
      // })
    }
    getInvoicedata();
  setApproveInvoiceVisible(false)
  setUploadProofPopup(false)
  setInvoiceProofFormData({
    file:null,
    file_error:null,
    file_link:''
  });
  proofUploadRef.current.clear();

  };

  const cancelApproveInvoice = () => {
    setApproveInvoiceVisible(false);
  };

  const getInvoiceCasesData = async (id: any) => {
  
    ApiGet(`invoiceDetails/get-invoice-cases-data?id=${id}`)
       .then((res: any) => {
                let finalData = res.data.result.map((item: any, index: number) => {
                     return {
                       SRNO: (lazyState.first) + index + 1,
                       id: item._id,              
                       BorrowerName: item.borrower_name ?? "-",
                       Loanamount: item.loan_amount ?? "-",
                       Financer: item.bank_name ?? "-",
                       Product: item?.product ?? "-",
                       dismonth:new Date(item?.disbursement_date).toISOString ?? "-",    
                       ...item          
                      };
                    });
                   setInvoiceCases(finalData);
                setViewCases(true)
          
              }).catch((error: any) => {
           // toast.error(error?.error, {
           //     position: "top-right",
           //     theme: "colored"
           // })
               })
}

  const getInvoiceImageData = async (path: any) => {
      if (path != undefined) {
        await ApiPost(`invoiceDetails/get-image-path`, {
          path,
        }).then((res: any) => {
          setViewInvoice(true)
          setInvoicePath(res.data.result);
        });
      } else {
        toast.error("Doc not uploaded", {
          position: "top-right",
          theme: "colored",
        });
      }
    };

    const getDowloadProofLink = async (path: any) => {
        if (path != undefined) {
          await ApiPost(`invoiceDetails/download-invoice`, {
            path,
          }).then((res: any) => {
            const linkElement = document.createElement("a");
            linkElement.href = res.data.result;
            linkElement.click();
          }).catch((error)=>{
            //  console.log(error)
          });
        } else {
          toast.error("Doc not uploaded", {
            position: "top-right",
            theme: "colored",
          });
        }
      };
  const handleMenuItemClick = (itemLabel) => {
    

     if(itemLabel== 'View Invoice'){
      if(selectedRowData.current?.invoice_path != null){
        getInvoiceImageData(selectedRowData.current?.invoice_path)
        setInvoicePathLink(selectedRowData.current?.invoice_path)
      }
      else{
        toast.error("Doc not uploaded", {
                  position: "top-right",
                  theme: "colored",
                });
      }
     }
     if (itemLabel === "Download Invoice") {
      if(selectedRowData.current.invoice_path != null){
        getDowloadProofLink(selectedRowData.current?.invoice_path)
      }
      else{
        toast.error("Doc not uploaded", {
          position: "top-right",
          theme: "colored",
        });
      }}
     if(itemLabel== 'Approve Invoice'){
      if(selectedRowData.current.status == 'Invoice raised'){
        setUploadProofPopup(true)
      }
      else{
        toast.error("Invoice already Approved / Completed / Rejected", {
          position: "top-right",
          theme: "colored",
        });
      }
     }
     if(itemLabel== 'Cases List'){
      getInvoiceCasesData(selectedRowData.current.id)
       }
     if(itemLabel== 'Reject Invoice'){
      if(selectedRowData.current.status == 'Invoice raised'){
        setRejectPopup(true)

      }else{
        toast.error("Can't reject the Invoice", {
          position: "top-right",
          theme: "colored",
        });
      }
         }
     if(itemLabel== 'View Invoice Proof'){
          if(selectedRowData.current?.invoice_proof_path != null){
            getInvoiceImageData(selectedRowData.current?.invoice_proof_path)
            setInvoicePathLink(selectedRowData.current?.invoice_proof_path)
          }
          else{
            toast.error("Doc not uploaded", {
                      position: "top-right",
                      theme: "colored",
                    });
          }
         }
      if (itemLabel === "Download Invoice Proof") {
            if(selectedRowData.current.invoice_proof_path != null){
              getDowloadProofLink(selectedRowData.current?.invoice_proof_path)
            }
            else{
              toast.error("Doc not uploaded", {
                position: "top-right",
                theme: "colored",
              });
      }
      
  };
  if(itemLabel== 'View Rejection reason'){
    if(selectedRowData.current.status == 'Invoice rejected'){
    setViewRejectReasonPopup(true)
      setInvoiceRejectData({...invoiceRejectData,comment:selectedRowData.current.reason})
       }
       else{
        toast.error("Not rejected", {
          position: "top-right",
          theme: "colored",
        });
       }
}}

  const handleEllipsisClick = (event, rowData) => {
    event.stopPropagation(); // Prevent event from bubbling up
    selectedRowData.current = rowData;
    if (menuRef.current) {
      menuRef.current.toggle(event);
    }
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <div>
        <img
          src="../assets/menu-cirlce.svg"
          alt="menu-icon"
          style={{ cursor: "pointer" }}
          onClick={(e) => handleEllipsisClick(e, rowData)}
        />
        <TieredMenu model={generateMenuItems()} popup ref={menuRef} />
      </div>
    );
  };

  const generateMenuItems = () => {
    const menuItems = [];
    // if (permission[PERMISSION_TYPE.ADMIN]) {
      menuItems.push({
        label: "Invoice Doc",
        items: [
          {
            label: "View Invoice",
            command: () => handleMenuItemClick("View Invoice"),
          },
          {
            label: "Download Invoice",
            command: () => handleMenuItemClick("Download Invoice"),
          },
        ],
        command: () => handleMenuItemClick("Invoice Doc"),
      });
      menuItems.push({
        label: "Cases List",
        command: () => handleMenuItemClick("Cases List"),
      });
      if (permission[PERMISSION_TYPE.ADMIN] || permission[PERMISSION_TYPE.STAFF_OPS]) {
      menuItems.push({
        label: "Approve Invoice",
        command: () => handleMenuItemClick("Approve Invoice"),
      });}
      menuItems.push({
        label: "Reject Invoice",
        command: () => handleMenuItemClick("Reject Invoice"),
      });
      menuItems.push({
        label: "Invoice Proof",
        items: [          
          {
            label: "View Invoice Proof",
            command: () => handleMenuItemClick("View Invoice Proof"),
          },
          {
            label: "Download Invoice Proof",
            command: () => handleMenuItemClick("Download Invoice Proof"),
          },
        ],
        command: () => handleMenuItemClick("Invoice Proof"),
      });
      menuItems.push({
        label: "View Rejection reason",
        command: () => handleMenuItemClick("View Rejection reason"),
      });
     
    
    return menuItems;
  };

 
  useEffect(() => {
    Promise.all([
   getInvoicedata(),
   getUserOptionsData(),
   getOptionsData()
  ])
  .then(() => {
    setTimeout(() => {
      setLoader(false);
    }, 250);
  })
  .catch((error) => {
    setLoader(false);
  });
}, [lazyState]);
  
    const rejectInvoice = async () => {
      setRejectPopup(true);
  
      if(invoiceRejectData.comment == ''){
        toast.error("Please Enter reason..!", {
          position: "top-right",
          theme: "colored",
        });
      }

      if(invoiceRejectData.comment != '')
    await  ApiPut(`invoiceDetails/update-invoice-status`,{
      id:selectedRowData.current.id,
      reason:invoiceRejectData.comment,
      type:'reject'
    })
        .then((res: any) => {
          setRejectPopup(false);
          setInvoiceRejectData(reject_data)
          getInvoicedata()
        })
        .catch((error: any) => {
          toast.error("Something went wrong!", {
            position: "top-right",
            theme: "colored",
          });
        });
    };
  
    const months = [
      { label: "January", value: 1 },
      { label: "February", value: 2 },
      { label: "March", value: 3 },
      { label: "April", value: 4 },
      { label: "May", value: 5 },
      { label: "June", value: 6 },
      { label: "July", value: 7 },
      { label: "August", value: 8 },
      { label: "September", value: 9 },
      { label: "October", value: 10 },
      { label: "November", value: 11 },
      { label: "December", value: 12 },
    ];
    const monthMap: Record<string, string> = {};
      months.forEach((month) => {
          monthMap[month.value] = month.label;
      });
 const userNameFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={userNameOptions}
        onChange={(e) => options.filterCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };
  const CreatedmonthFilterTemplate = (options) => {
    
    const years=[];
    let currentYear;
    let currentMonth = new Date().getMonth();
              if(currentMonth <= 6){
                 currentYear = new Date().getFullYear();
              }
              else{
                 currentYear = new Date().getFullYear()+1;
              }  
              for (let i = currentYear; i >= 2018; i--) {
                years.push({ label: i.toString(), value: i });
              }  

    const onApplyFilter = () => {
      options.filterApplyCallback({ month: selectedMonth, year: selectedYear });
    };

    const onClearFilter = () => {
      setSelectedMonth(null);
      setSelectedYear(null);
      options.filterApplyCallback({ month: null, year: null });
    };

    const isBothSelected = selectedMonth && selectedYear;
    return (
      <div style={{ minWidth: "14rem" }}>
        <div className="mb-2">
          <Dropdown
            optionLabel="label"
            optionValue="value"
            value={selectedMonth}
            options={months}
            onChange={(e) =>{ 
              
              setSelectedMonth(e.value)}}
            placeholder="Select Month"
            className="p-column-filter"
            filter
          />
        </div>
        <div className="mb-2">
          <Dropdown
            optionLabel="label"
            optionValue="value"
            value={selectedYear}
            options={years}
            onChange={(e) => setSelectedYear(e.value)}
            placeholder="Select Year"
            className="p-column-filter"
            filter
          />
        </div>
        <div className="p-column-filter-buttonbar" style={{ padding: "0" }}>
          <Button
            label="Clear"
            onClick={onClearFilter}
            className="p-button p-component p-button-outlined p-button-sm"
          />
          <Button
            label="Apply"
            onClick={onApplyFilter}
            className="p-button p-component p-button-sm"
            disabled={!isBothSelected}
          />
        </div>
      </div>
    );
  };

  const onSelect = (e: FileUploadSelectEvent, setTotalSize, setFiles, link) => {
      let totalSize = 0;
      let files = e.files;
  
      for (let i = 0; i < files.length; i++) {
        totalSize += files[i].size || 0;
      }
  
      setTotalSize(totalSize);
      setFiles(files);
    };
  
    const onUpload = (e: FileUploadUploadEvent, setTotalSize) => {
      let totalSize = 0;
  
      e.files.forEach((file) => {
        totalSize += file.size || 0;
      });
      setTotalSize(totalSize);
    };
  
    const onClear = (setTotalSize) => {
      setTotalSize(0);
    };
  
    const onRemove = (e, totalSize, setTotalSize) => {
      setTotalSize(totalSize - (e.file.size || 0));
    };
    const headerTemplate =
      (totalSize) => (options: FileUploadHeaderTemplateOptions) => {
        const { className, chooseButton } = options;
        const value = totalSize / 10000;
        const formattedValue = totalSize ? `${totalSize / 1000} KB` : "0 B";
  
        return (
          <div
            className={className}
            style={{
              backgroundColor: "transparent",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                flex: "1",
              }}
            >
              {chooseButton}
            </div>
            <div className="progress-container">
              <span>{formattedValue} / 1 MB</span>
              <ProgressBar
                value={value}
                showValue={false}
                className="custom-progress-bar"
              ></ProgressBar>
            </div>
          </div>
        );
      };

      const itemTemplate = (file, props) => {
        return (
          <div className="flex align-items-center flex-wrap">
            <div className="flex align-items-center" style={{ width: "40%" }}>
              <span>{file.name}</span>
            </div>
            <Button
              type="button"
              raised
              severity="info"
              icon="pi pi-times"
              className="p-button-outlined p-button-rounded p-button-danger ml-auto"
              onClick={() => props.onRemove(file)}
            />
          </div>
        );
      };
  const invoiceStatusFilterTemplate = (options) => {
      return (
        <MultiSelect
          value={options.value}
          options={invoiceStatusOptions}
          onChange={(e) => options.filterCallback(e.value)}
          optionLabel="label"
          placeholder="Select"
          className="p-column-filter"
          maxSelectedLabels={1}
          style={{ minWidth: "14rem" }}
          filter
          filterPlaceholder="Search"
        />
      );
    };
  
  return (
    <div className="overflow-x-hidden">
      {/* <Sidebar />
<Header /> */}
      {loader ? (
        <>
          {/* START::OVERLAY */}
          <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
            <img src="assets/logo.svg" />
          </div>
          {/* END::OVERLAY */}
        </>
      ) : (
        <>
          {/* <main className="lg:pl-[280px] pt-[92px]"> */}
          <div className="p-4 sm:p-9 space-y-5">
            {/* START::DASHBOARD HEADER */}
            <div className="flex flex-wrap gap-5 items-center justify-between space-y-5">
            <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9 space-y-5 md:space-y-0">
            <h1 className="text-[22px] text-light-gray font-semibold">Invoice Data</h1>
            </div>
             
              </div>

            <DataTable
              value={invoiceDetail}
              lazy
              dataKey="id"
              showGridlines
              paginator
              first={lazyState.first}
              filters={lazyState.filters}
              onFilter={onFilter}
              rows={lazyState.rows}
              rowsPerPageOptions={[10, 20, 30, 40, 50]}
              totalRecords={totalRecords}
              onPage={onPage}
              loading={loader}
              size={"small"}
              scrollable
              scrollHeight="600px"
              className="custom-datatable"
              header={header}
              emptyMessage="No records found."
              
            >
              <Column field="SRNO" header="SR.NO." />   
              <Column field="user_name" header="User Name" 
              showFilterMatchModes={false}
              filterMenuStyle={{ minWidth: "14rem" }}
              filter
              filterElement={userNameFilterTemplate}
              />
              <Column field="amount" header="Amount" />
              <Column field="userinvoicenumber" header="User Invoice No." />
              <Column field="invoicenumber" header="Orenda Invoice No." />
              <Column field="Created_at" header="Generation Date"
              showFilterMatchModes={false}
              showApplyButton={false}
              showClearButton={false}
              filterMenuStyle={{ minWidth: "14rem" }}
              filter
              filterElement={CreatedmonthFilterTemplate}
              />
              <Column field="status" header="Invoice status" 
              showFilterMatchModes={false}
              filterMenuStyle={{ minWidth: "14rem" }}
              filter
              filterElement={invoiceStatusFilterTemplate}
              />
              <Column
                field="Action"
                header="Action"
                body={actionBodyTemplate}
              />
            </DataTable> 

          </div>
          {/* </main> */}

          {/* Start::Add Disbursement Data Modal */}
         
       
       <div
                   className={`tw-modal ${veiwCases ? "flex" : "hidden"} `}
                   style={{
                       overflowX: 'auto'
                     }}
                 >
                   <div className="tw-modal-content"
                   style={{
                    maxWidth:'1865px',
                    minWidth:'900px',
                    height: "900px",
                       overflowX: 'auto'
                     }}
                    >
                     <div className="tw-modal-title "
                     style={{height:'50px',
                      maxWidth:'1865px',
                      minWidth:'900px',
                                             }}>
                       <div> Disbursement List</div>
                       <button
                         onClick={() => {
                           setViewCases(false);
                           setInvoiceCases([])
                         }}
                       >
                         <img src="assets/close-outline.svg" alt="" />
                       </button>
                     </div>
                     <div  style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' ,height:'750px' }}>
                     
                    <div className="tw-modal-body w-full h-full p-10">
                       <div className=""
                        style={{
                          maxWidth:'1865px',
                          minWidth:'900px',
                         height:"700px"
                       }}>

                     <div className="gap-5 space-y-5 w-full h-full mt-5">
                     <DataTable
                    //  ref={dt}
                     value={invoiceCases}
                     lazy
                     dataKey="id"
                     loading={loader}
                     size={"small"}
                     scrollable
                     scrollHeight="600px"
                     className="custom-datatable"
                     emptyMessage="No records found."
                     height={'600px'}
                   >
                     <Column field="SRNO" header="SR.NO." />
                     
                     <Column field="BorrowerName" header="Borrower Name" />
                     <Column field="Loanamount" header="Loan Amount" />
                     <Column
                       field="Financer"
                       header="Financer"
                       
                     />
                     <Column
                       field="Product"
                       header="Product"
                       
                     />
                     
                     {/* ) : null} */}
                   </DataTable>
                     </div>
                     </div>
                   
      
         
                                </div>
                 </div>
                 </div></div>
          <div
            className={`tw-modal ${viewInvoice ? "flex" : "hidden"} !items-start`}
          >
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                <div>Invoice Doc</div>
                <button
                  onClick={() => {
                    setViewInvoice(false)
                    setInvoicePath('')
                  }}
                >
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>

              <div className="tw-modal-body">                
               
              {invoicePathLink ?  invoicePathLink?.split(".").pop() == "pdf" ?
 
                        <iframe
                          src={`data:application/pdf;base64,${invoicePath}`}
                          title="Preview PDF"
                          width="100%"
                          height="1000px"
                        />
                        :
                        <img
                         style={{ height: "500px", width: "500px" }}
                         src={`data:image/jpeg;base64,${invoicePath}`}
                         className="ml-5 object-center object-cover"
                       />   
                        : null}    
              </div>

             </div>
          </div>

          <div
          className={`tw-modal ${
            rejectPopup ? "flex" : "hidden"
          } !items-start`}
        >
          <div className="tw-modal-content">
            <div className="tw-modal-title">
              <div>Reject Invoice </div>
              <button onClick={() => {
                setRejectPopup(false);
                setInvoiceRejectData(reject_data)
}
              }>
                <img src="assets/close-outline.svg" alt="" />
              </button>
            </div>

            <div className="tw-modal-body">
              <div className="space-y-1">
                {/* <p className="input-label">Cheque</p> */}
                <div className="md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0">
                  <div className="w-full md:flex md:space-x-5 md:space-y-0 space-y-5 !mb-4">
                    <div className="w-full space-y-1">
                      <div id="comment " className="input-wrap w-full">
                        <input
                          type="text"
                          className="block w-full text-sm text-[#808080]"
                          placeholder="Reason"
                          value={invoiceRejectData.comment}
                          onChange={(e: any) => {
                            setInvoiceRejectData({
                              ...invoiceRejectData,
                              comment: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="tw-modal-footer">
              <button
                className="tw-modal-footer-button bg-secondary text-white"
                onClick={() => {
                  rejectInvoice()
                }}
              >
                Submit
              </button>
              <button
                className="tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary"
                onClick={() => {
                  setRejectPopup(false);
                  setInvoiceRejectData(reject_data)

                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>

        <div
          className={`tw-modal ${
            viewRejectReasonPopup ? "flex" : "hidden"
          } !items-start`}
        >
          <div className="tw-modal-content">
            <div className="tw-modal-title">
              <div>Invoice rejection reason</div>
              <button onClick={() => {setViewRejectReasonPopup(false);
                                  setInvoiceRejectData(reject_data)}
              }>
                <img src="assets/close-outline.svg" alt="" />
              </button>
            </div>

            <div className="tw-modal-body">
              <div className="space-y-1">
                {/* <p className="input-label">Cheque</p> */}
                <div className="md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0">
                  <div className="w-full md:flex md:space-x-5 md:space-y-0 space-y-5 !mb-4">
                    <div className="w-full space-y-1">
                      <div id="comment " className="input-wrap w-full">
                        <input
                          type="text"
                          className="block w-full text-sm text-[#808080]"
                          placeholder="Reason"
                          value={invoiceRejectData.comment}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="tw-modal-footer">
              <button
                className="tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary"
                onClick={() => {
                  setViewRejectReasonPopup(false);
                  setInvoiceRejectData(reject_data)
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        <div
                    className={`tw-modal ${uploadProofPopup ? "flex" : "hidden"}`}
                  >
                    <div className="tw-modal-content">
                      <div className="tw-modal-title">
                        Upload Invoice Proof
                        <button
                          onClick={() => {
                            setUploadProofPopup(false);
                            proofUploadRef.current.clear();
                            setInvoiceProofFormData({
                              file:null,
                              file_error:null,
                              file_link:''
                            });
                          }}
                        >
                          <img src="assets/close-outline.svg" alt="" />
                        </button>
                      </div>
                      <div className={`tw-modal-body `}>
                        <div
                          className={`${
                            invoiceProofFormData.file_error ? " border-2 border-red-500 " : ""
                          }`}
                        >
                          <FileUpload
                            // mode="basic"
                            ref={proofUploadRef}
                            name="demo[]"
                            accept=".jpg,.jpeg,.png,.gif,.pdf"
                            maxFileSize={1000000}
                            // onUpload={(e) => onUpload(e, setFileUpload1Size)}
                            onSelect={(e) => {
                              onSelect(
                                e,
                                setProofUploadSize,
                                setProofUploadFiles,
                                "pan"
                              );
                              setInvoiceProofFormData({
                                ...invoiceProofFormData,
                                file: e.files[0],
                                file_link: e.files[0].name,
                                file_error: null,
                              });
                            }}
                            onError={() => onClear(setProofUploadSize)}
                            onClear={() => onClear(setProofUploadSize)}
                            onRemove={(e) =>
                              onRemove(e, proofUploadSize, setProofUploadSize)
                            }
                            headerTemplate={headerTemplate(proofUploadSize)}
                            itemTemplate={itemTemplate}
                            emptyTemplate={
                              <p className="m-0">
                                Drag and drop files to here to upload.
                              </p>
                            }
                          />
                        </div>
                        <p className="text-red-500 text-sm">
                          {invoiceProofFormData.file_error}
                        </p>
                      </div>
                      <div className="tw-modal-footer">
                        <Button
                          icon="pi pi-check"
                          raised
                          severity="info"
                          onClick={() => {

                            if(invoiceProofFormData.file == null){

                              toast.error("Please select File!!!", {
                                    position: "top-right",
                                    theme: "colored",
                                  });
                            }else{
                              setApproveInvoiceVisible(true)

                            }
                          }}
                        >
                          <span style={{ marginLeft: "8px" }}> Save</span>
                        </Button>
                        <Button
                          icon="pi pi-times"
                          raised
                          severity="danger"
                          onClick={() => {
                            setUploadProofPopup(false);
                            proofUploadRef.current.clear();
                             setInvoiceProofFormData({
                              file:null,
                              file_error:null,
                              file_link:''
                            });
                          }}
                        >
                          {" "}
                          <span style={{ marginLeft: "8px" }}> Cancel</span>
                        </Button>
                      </div>
                    </div>
                  </div>
          <ConfirmDialog
            // group="declarative"
            visible={approveInvoiceVisible}
            onHide={() => setApproveInvoiceVisible(false)}
            message="Are you sure you want to Approve Invoice?"
            header="Confirmation"
            icon="pi pi-exclamation-triangle"
            accept={approveInvoice}
            reject={cancelApproveInvoice}
            style={{ width: "50vw" }}
            breakpoints={{ "1100px": "75vw", "960px": "100vw" }}
          />
          </>
          


      )}
</div>
  );
};

export default InvoiceAdminData;
