import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import React from "react";
import Select from 'react-select';
import { ApiGet, ApiPost } from "../../helper/API/ApiData";
import { STATE } from "../../constants";
import { toast } from "react-toastify";
import TableComponent from "../../components/Tables";
import { TableCell, TableRow } from "@mui/material";
import * as XLSX from 'xlsx';
import DatePicker from "react-datepicker";
import { format, parseISO } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import { TieredMenu } from "primereact/tieredmenu";
import { Dropdown } from "primereact/dropdown";
import { useNavigate } from "react-router";
import { FileUpload, FileUploadHeaderTemplateOptions } from "primereact/fileupload";
import moment from "moment";

const ReverseMIS = () => {

    const fileInputRef = useRef(null);

    let reverseMisFormData = {
        selectedMonth: "",
        selectedYear: "",
        selectedFile: null,
        selected_month_error: null,
        selected_year_error: null,
        selected_File_error: null
    };
    const [uploadImageSize, setuploadImageSize] = useState(0);
    const [fileUpload1Files, setFileUploadFiles] = useState([]);
    const uploadImageRef = useRef<FileUpload>(null);
    const [loader, setLoader] = useState(false);
    const [addUploadFileModel, setUploadFileModel] = React.useState(false);
    const [formData, setFormData] = useState<any>(reverseMisFormData);
    const [reverseMisData, setReverseMisData] = useState<any>([]);
    const [state, setState] = useState(STATE.DEFAULT_STATE);
     const [searchValue, setSearchValue] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    let navigate=useNavigate();
    // Generate an array of months
    const months = [
        { label: 'January', value: 1 },
        { label: 'February', value: 2 },
        { label: 'March', value: 3 },
        { label: 'April', value: 4 },
        { label: 'May', value: 5 },
        { label: 'June', value: 6 },
        { label: 'July', value: 7 },
        { label: 'August', value: 8 },
        { label: 'September', value: 9 },
        { label: 'October', value: 10 },
        { label: 'November', value: 11 },
        { label: 'December', value: 12 }
      ];
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 0,
        search: '',
        filters: {
            month:{value: null, matchMode: FilterMatchMode.IN},
            year:{value: null, matchMode: FilterMatchMode.IN},
        }
      });
    const monthMap: Record<string, string> = {};
    months.forEach((month) => {
        monthMap[month.value] = month.label;
    });

    // Generate an array of years from 2020 to 2030
    const years = Array.from({ length: 11 }, (_, index) => {
        const yearValue = 2020 + index;
        return { value: yearValue, label: yearValue.toString() };
    });

    const handleMonthChange = (selectedOption: any) => {
        setFormData({
            ...formData,
            selectedMonth: selectedOption ? selectedOption.value : '',
            selected_month_error: null,
        });
    };

    const handleYearChange = (selectedOption: any) => {
        setFormData({
            ...formData,
            selectedYear: selectedOption ? selectedOption.value : '',
            selected_year_error: null,
        });
    };

    const getReverseMisData = () => {
        setLoader(true);
    const modifiedLazyState = { ...lazyState, limit: lazyState.rows };
    delete modifiedLazyState.rows;
    delete modifiedLazyState['multiSortMeta'];
    delete modifiedLazyState['sortField'];
    delete modifiedLazyState['sortOrder'];

    // Function to replace empty arrays in the "value" field with null
    const replaceEmptyValueArrays = (obj) => {
      for (const key in obj) {
        if (key === 'value' && Array.isArray(obj[key]) && obj[key].length === 0) {
          obj[key] = null;
        } else if (typeof obj[key] === 'object') {
          replaceEmptyValueArrays(obj[key]);
        }
      }
      return obj;
    };

    // Call the function to replace empty arrays in the "value" field with null
    const finalModifiedLazyState = replaceEmptyValueArrays(modifiedLazyState);
    finalModifiedLazyState.page++;
        ApiPost(`bankdisbursement/get_orendadisbursement_master`,finalModifiedLazyState)
            .then((res: any) => {
                setTotalRecords(Number(res.data.count[0].count));
                let temp = res?.data?.orendadisbusementmaster_data?.map((item: any, index: number) => {
                    return {
                        id: item._id,
                        SRNO: (((state.page - 1) * state.limit) + index + 1),
                        createdDate: formatDate(item.created_at),
                        month: getMonthString(item.disbursement_month) ,
                        year: item.disbursement_year
                    }
                });
                setReverseMisData(temp);
                setLoader(false);
            });
    }

    useEffect(() => {
        Promise.all([
            getReverseMisData()
        ]).then(() => {
            setTimeout(() => { setLoader(false) }, 250)
        }).catch((error) => {
            setLoader(false)
        })
    }, [lazyState]);

    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            selectedFile: e.target.files[0] ? e.target.files[0] : null,
            selected_File_error: null,
        });
    };

    const handleResetFile = () => {
        uploadImageRef.current.clear();
    };

    const onPage = (event) => {
        let filter = { ...lazyState };
        filter = event;
        filter['search'] = lazyState.search;
        setlazyState(filter);
      };
    
      const onFilter = (event) => {
        event['first'] = 0;
        event['page'] = 0;
        setlazyState(event);
      };
    
      const onGlobalFilterChange = (e) => {
        const value = e;
        let filter = { ...lazyState };
    
        filter['search'] = value;
    
        setlazyState(filter);
      };
    
      const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          onGlobalFilterChange(searchValue);
        }
      };
    
      const handleSearchClick = () => {
        onGlobalFilterChange(searchValue);
      };
    
      const handleInputChange = (event) => {
        setSearchValue(event.target.value);
      };
    
      const renderHeader = () => {
        return (
          <div className="flex justify-end">
            <span>
              <InputText
                className="mr-2"
                value={searchValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder="Keyword Search"
              />
              <Button icon="pi pi-search" onClick={handleSearchClick} />
            </span>
          </div>
        );
      };
    
      const header = renderHeader();

    const addReverseMisData = async () => {
        let error = { ...formData };
        if (formData.selectedMonth == "") {
            error = { ...error, selected_month_error: "Please choose a month" };
        }
        if (formData.selectedYear == "") {
            error = { ...error, selected_year_error: "Please choose a year" };
        }
        if (formData.selectedFile == null) {
            error = { ...error, selected_File_error: "Please select a file" };
        }
        setFormData({
            ...error
        });
        if (error.selected_month_error == null && error.selected_year_error == null && error.selected_File_error == null) {
            const file = formData.selectedFile;

            if (file) {
                try {
                    const excelData = await readExcelFile(file);
                    console.log('------------',excelData)
                    let fileData: any[] = [];

                    (excelData as any[]).forEach((element: any) => {
                        const data = {
                            'file_sequence_num': element['File_Sequence_Num'] || 0,
                            'pymt_prod_type_code': element['Pymt_Prod_Type_Code'] || '',
                            'pymt_mode': element['Pymt_Mode'] || '',
                            'debit_acct_no': element['Debit_Acct_no'] || 0,
                            'beneficiary_name': element['Beneficiary Name'] || '',
                            'beneficiary_account_no': element['Beneficiary Account No'] || 0,
                            'bene_ifsc_code': element['Bene_IFSC_Code'] || '',
                            'amount': element['Amount'] || 0,
                            'debit_narration': element['Debit narration'] || '',
                            'credit_narration': element['Credit narration'] || '',
                            'mobile_number': element['Mobile Numder'] || 0,
                            'email_id': element['Email id'] || '',
                            'remark': element['Remark'] || '',
                            'pymt_date': moment(element['Pymt_Date'], 'DD-MM-YYYY').format('YYYY-MM-DD') || '',
                            'reference_no': element['Reference_no'] || '',
                            'addl_info1': element['Addl_Info1'] || '',
                            'addl_info2': element['Addl_Info2'] || '',
                            'addl_info3': element['Addl_Info3'] || '',
                            'addl_info4': element['Addl_Info4'] || '',
                            'addl_info5': element['Addl_Info5'] || '',
                            'status': element['STATUS'] || '',
                            'current_step': element['Current Step'] || '',
                            'file_name': element['File name'] || '',
                            'rejected_by': element['Rejected by'] || '',
                            'rejection_reason': element['Rejection Reason'] || '',
                            'acct_debit_date': moment(element['Acct_Debit_date'], 'DD-MM-YYYY').format('YYYY-MM-DD') || '',
                            'customer_ref_no': element['Customer Ref No'] || 0,
                            'utr_no': element['UTR NO'].toString() || ''

                        };
                        fileData.push(data);
                    });

                    const request = {
                        "month": formData.selectedMonth.toString(),
                        "year": formData.selectedYear.toString(),
                        "data": fileData
                    };

                    await ApiPost(`bankdisbursement/upload_orenda_disbursement`, request)
                        .then((res: any) => {
                            if (res?.success == true) {
                                toast.success("file uploaded Successfully.", {
                                    position: "top-right",
                                    theme: "colored"
                                })
                            }
                            resetFormData();
                            getReverseMisData();
                            setUploadFileModel(false);
                        }).catch((error: any) => {
                            console.log(error)
                            setUploadFileModel(false);
                            toast.error("Something went wrong", {
                                position: "top-right",
                                theme: "colored"
                            })
                        })

                } catch (error) {
                    console.error('Error reading file:', error);
                }
            }
        }
    };

    const menuRef = useRef(null);
    const selectedRowData = useRef(null);
    const actionBodyTemplate = (rowData) => {
        return (
          <div>
            <img src="../assets/menu-cirlce.svg" alt="menu-icon" style={{ cursor: 'pointer' }} onClick={(e) => handleEllipsisClick(e, rowData)} />
            <TieredMenu model={generateMenuItems()} popup ref={menuRef} />
          </div>
        );
      };
    
      const generateMenuItems = () => {
        return [
          { label: 'View', command: () => handleMenuItemClick('View') },
        ];
      };

      const handleEllipsisClick = (event, rowData) => {
        event.stopPropagation(); // Prevent event from bubbling up
        selectedRowData.current = rowData;
        if (menuRef.current) {
          menuRef.current.toggle(event);
        }
      };

      const handleMenuItemClick=(label)=>{
        if( label === "View")
           {
            navigate("/reversemis/" + selectedRowData.current.id);
        }
     }
    const readExcelFile = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                const data = event.target?.result;
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const excelData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName],{
                    defval: '', // Set default value for empty cells
                  });
                const filteredData = excelData.filter(row => {
                    return Object.values(row).some(value => value !== '');
                  });
                
                resolve(filteredData);
            };

            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsArrayBuffer(file);
        });
    };

    const resetFormData = () => {
        setFormData({
            ...formData,
            selectedMonth: '',
            selectedYear: '',
            selectedFile: null,
            selected_month_error: '',
            selected_year_error: '',
            selected_File_error: ''
        });
        handleResetFile();
        setUploadFileModel(false);
        // Add any other state variables you want to reset to their initial values
    };

    let columns = [
        { id: 'SRNO', label: 'SR.NO.' },
        { id: 'month', label: 'Month' },
        { id: 'year', label: 'Year' },
        { id: 'uploadedDate', label: 'Uploaded Date' }
    ];

    // Helper function to convert numeric month to string
    const getMonthString = (month: string) => {
        const months = [
            'January', 'February', 'March', 'April',
            'May', 'June', 'July', 'August',
            'September', 'October', 'November', 'December'
        ];
        return months[parseInt(month, 10) - 1]; // Subtract 1 because array is zero-based
    };

    const openModal = () => {
        resetFormData(); // Ensure reset is called before opening the modal
        setUploadFileModel(true);
    };

    const formatDate = (dateString) => {
        try {
            const date=new Date(dateString)
            // if (isNaN(dateString.getTime())) {
            //     return "Invalid Date";
            // }

            return format(date, 'dd-MM-yyyy HH:mm:ss');
        } catch (error) {
            console.error('Error parsing date:', error);
            return "Invalid Date";
        }
    };

    let viewDataColumns = [
        { id: 'file_sequence_num', label: 'File Sequence Number' },
        { id: 'pymt_prod_type_code', label: 'Product Type Code' },
        { id: 'pymt_mode', label: 'Payment Mode' },
        { id: 'debit_acct_no', label: 'Debit Account Number' },
        { id: 'beneficiary_name', label: 'Beneficiary Name' },
        { id: 'beneficiary_account_no', label: 'Beneficiary Account Number' },
        { id: 'bene_ifsc_code', label: 'Beneficiary IFSC Code' },
        { id: 'amount', label: 'Amount' },
        { id: 'debit_narration', label: 'Debit Narration' },
        { id: 'credit_narration', label: 'Credit Narration' },
        { id: 'mobile_number', label: 'Mobile Number' },
        { id: 'email_id', label: 'Email Id' },
        { id: 'remark', label: 'Remark' },
        { id: 'pymt_date', label: 'Payment Date' },
        { id: 'reference_no', label: 'Reference Number' },
        { id: 'addl_info1', label: 'Additional Info1' },
        { id: 'addl_info2', label: 'Additional Info2' },
        { id: 'addl_info3', label: 'Additional Info3' },
        { id: 'addl_info4', label: 'Additional Info4' },
        { id: 'addl_info5', label: 'Additional Info5' },
        { id: 'status', label: 'Status' },
        { id: 'current_step', label: 'Step' },
        { id: 'file_name', label: 'File Name' },
        { id: 'rejected_by', label: 'Rejected By' },
        { id: 'rejection_reason', label: 'Rejection Reason' },
        { id: 'acct_debit_date', label: 'Account Debit Date' },
        { id: 'customer_ref_no', label: 'Customer Reference Number' },
        { id: 'utr_no', label: 'UTR Number' },
    ];

    const itemTemplate = (file, props) => {
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{ width: '40%' }}>
                    <span>{file.name}</span>
                </div>
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => props.onRemove(file)} />
            </div>
        );
    };
    const headerTemplate = (totalSize) => (options: FileUploadHeaderTemplateOptions) => {
        const { className, chooseButton } = options;
    
        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: '1' }}>
                    {chooseButton}
                </div>
            </div>
        );
    };

    const customStyles = (hasError) => ({
        control: (base, state) => ({
          ...base,
          borderColor: hasError ? 'red' : base.borderColor,
          '&:hover': {
            borderColor: hasError ? 'red' : base.borderColor,
          },
        }),
      });

      const monthRowFilterTemplate = (options) => {
        return (
          <MultiSelect
            value={options.value}
            options={months}
            onChange={(e) => options.filterCallback(e.value)}
            optionLabel="label"
            placeholder="Select"
            className="p-column-filter"
            maxSelectedLabels={1}
            style={{ minWidth: '14rem' }}
            filter
            filterPlaceholder="Search"
          />
        );
      };
      const yearRowFilterTemplate = (options) => {
        return (
          <MultiSelect
            value={options.value}
            options={years}
            onChange={(e) => options.filterCallback(e.value)}
            optionLabel="label"
            placeholder="Select"
            className="p-column-filter"
            maxSelectedLabels={1}
            style={{ minWidth: '14rem' }}
            filter
            filterPlaceholder="Search"
          />
        );
      };
      
      const DismonthFilterTemplate = (options) => {
        const months = [
          { label: 'January', value: 1 },
          { label: 'February', value: 2 },
          { label: 'March', value: 3 },
          { label: 'April', value: 4 },
          { label: 'May', value: 5 },
          { label: 'June', value: 6 },
          { label: 'July', value: 7 },
          { label: 'August', value: 8 },
          { label: 'September', value: 9 },
          { label: 'October', value: 10 },
          { label: 'November', value: 11 },
          { label: 'December', value: 12 }
        ];
    
        const years = [];
        const currentYear = new Date().getFullYear();
        for (let i = currentYear; i >= 2000; i--) {
          years.push({ label: i.toString(), value: i });
        }
    
        const onApplyFilter = () => {
          options.filterApplyCallback({ month: selectedMonth, year: selectedYear });
        };
    
        const onClearFilter = () => {
          setSelectedMonth(null);
          setSelectedYear(null);
          options.filterApplyCallback({ month: null, year: null });
        };
    
        const isBothSelected = selectedMonth && selectedYear;
    
        return (
          <div style={{ minWidth: '14rem' }}>
            <div className='mb-2'>
              <Dropdown
                optionLabel="label"
                optionValue="value"
                value={selectedMonth}
                options={months}
                onChange={(e) => setSelectedMonth(e.value)}
                placeholder="Select Month"
                className="p-column-filter"
                filter
              />
            </div>
            <div className='mb-2'>
              <Dropdown
                optionLabel="label"
                optionValue="value"
                value={selectedYear}
                options={years}
                onChange={(e) => setSelectedYear(e.value)}
                placeholder="Select Year"
                className="p-column-filter"
                filter
              />
            </div>
            <div className='p-column-filter-buttonbar' style={{ padding: '0' }}>
              <Button label="Clear" onClick={onClearFilter} className="p-button p-component p-button-outlined p-button-sm" />
              <Button label="Apply" onClick={onApplyFilter} className="p-button p-component p-button-sm" disabled={!isBothSelected} />
            </div>
          </div>
        );
      };
    

    return (<div className="overflow-x-hidden"
    // onClick={

    //     () => 
      
    //     getReverseMisData('')
      
    //   }
    
    >
        {loader ?
            <>
                {/* START::OVERLAY */}
                <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
                    <img src="assets/logo.svg" />
                </div>
                {/* END::OVERLAY */}
            </>
            :
            <>
                <div className="p-4 sm:p-9 space-y-5">
                    <div className="flex flex-wrap gap-5 items-center justify-between">
                        <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9 space-y-5 md:space-y-0">
                            <h1 className="text-[22px] text-light-gray font-semibold">Reverse MIS</h1>
                        <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">
                            <li>
                                <button className={`py-2 px-7 text-xs font-medium`} onClick={() => {
                                    openModal()
                                }}>Upload Reverse MIS</button>
                            </li>
                        </ul>                       
                        </div>
                    </div>
                    {/* Start:: Add Bank Disbursement Modal */}
                    <div className={`tw-modal ${addUploadFileModel ? 'flex' : 'hidden'}`}>
                        <div className="tw-modal-content">
                            <div className="tw-modal-title">
                                <div>Upload File</div>
                                <button onClick={() => setUploadFileModel(false)}>
                                    <img src="assets/close-outline.svg" alt="" />
                                </button>
                               
                            </div>
                         
                            <div className='tw-modal-body py-6'>
                                <div className='d-flex'>
                                    <div className="space-y-1">
                                        <label htmlFor="selectedMonth" className={`input-label ${formData.selected_month_error ? "text-red-500" : ""}`}>Month<span style={{color:'red'}}>*</span></label>
                                        <div id="selectedMonth" className={`${formData.selected_month_error ? "border-red-500" : ""}`}>
                                            <Select
                                                key={formData.selectedMonth}
                                                options={months}
                                                value={months.find((month) => month.value === formData.selectedMonth)}
                                                onChange={handleMonthChange}
                                                placeholder="Select Month"
                                                styles={customStyles(formData.selected_month_error)}
                                            />
                                        </div>
                                        <p className="text-red-500 text-sm">{formData.selected_month_error}</p>
                                    </div>
                                </div>
                            </div>

                            <div className='tw-modal-body py-6'>
                                <div className='d-flex'>
                                    <div className="space-y-1">
                                        <label htmlFor="selectedYear" className={`input-label ${formData.selected_year_error ? "text-red-500" : ""}`}>Year<span style={{color:'red'}}>*</span></label>
                                        <div id="selectedYear" className={`${formData.selected_year_error ? "border-red-500" : ""}`}>
                                            <Select
                                                key={formData.selectedYear}
                                                options={years}
                                                value={years.find((year) => year.value === formData.selectedYear)}
                                                onChange={handleYearChange}
                                                placeholder="Select Year"
                                                styles={customStyles(formData.selected_year_error)}
                                            />
                                        </div>
                                        <p className="text-red-500 text-sm">{formData.selected_year_error}</p>
                                    </div>
                                </div>
                            </div>

                            <div className='tw-modal-body py-6'>
                                <div className='d-flex'>
                                    <div className="space-y-1">
                                    <div>
              <h4 className="font-semibold text-light-gray text-lg">Choose File<span style={{color:'red'}}>*</span></h4>
              <FileUpload 
                    // mode="basic"
                    ref={uploadImageRef}
                    name="demo[]"
                    accept=".xls, .xlsx, .csv"
                    maxFileSize={2000000}
                    itemTemplate={itemTemplate}
                    emptyTemplate={<p className="m-0">Drag and drop files here to upload.</p>}
                    headerTemplate={headerTemplate(uploadImageSize)}
                    onSelect={(e) => {
                        setuploadImageSize(e.files.length);
                        setFileUploadFiles(e.files)
                        setFormData({
                            ...formData,
                            selectedFile: e.files[0] ? e.files[0] : null,
                            selected_File_error: null,
                        });
                      }
                  }
                    />
              </div>              
                                        {/* <ul className="flex space-x-[18px]">
                                            <li>
                                                <div className="flex justify-center item-center gap-x-2 w-full">
                                                    <label className='px-16 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border  bg-light-gray border-light-gray'>
                                                        <span>
                                                            Choose File
                                                        </span>
                                                        <input type="file" accept=".xls, .xlsx, .csv" style={{ display: "none" }} ref={fileInputRef} onChange={handleFileChange} />
                                                    </label>

                                                    <label className="input-wrap min-w-[calc(100vw-944px)] disabled cursor-no-drop text-sm text-[#808080]">{formData?.selectedFile?.name?.toString()} </label>
                                                </div>
                                                <p className="text-red-500 text-sm">{formData.selected_File_error}</p>
                                            </li>
                                        </ul> */}
                                    </div>
                                </div>
                            </div>

                            <div className='tw-modal-footer'>
                                <button className='tw-modal-footer-button bg-secondary text-white'
                                    onClick={() => {
                                        addReverseMisData();
                                    }}>
                                    {"Upload"}
                                </button>
                                <button className='tw-modal-footer-button bg bg-secondary bg-opacity-10 text-secondary' onClick={() => {
                                    setUploadFileModel(false);
                                    resetFormData();
                                }}>Cancel</button>
                            </div>
                        </div>
                    </div>
                    {/* End:: Add Bank Disbursement Modal */}

             <DataTable
              value={reverseMisData}
              lazy
              dataKey="id"
              showGridlines
              paginator
              first={lazyState.first}
              filters={lazyState.filters}
              onFilter={onFilter}
              rows={lazyState.rows}
              rowsPerPageOptions={[10, 20, 30, 40, 50]}
              totalRecords={totalRecords}
              onPage={onPage}
              loading={loader}
              header={header}
              emptyMessage="No records found."
              size={'small'}
              scrollable
              scrollHeight="600px"
              className="custom-datatable"
            >
              <Column
                field="SRNO"
                header="SR.NO."
              />
              <Column
                field="month"
                header="Month"
                showFilterMatchModes={false} filterMenuStyle={{ minWidth: '14rem' }}
                filter filterElement={monthRowFilterTemplate} 
              />
              <Column
                field="year"
                header="Year"
                showFilterMatchModes={false} filterMenuStyle={{ minWidth: '14rem' }}
                filter filterElement={yearRowFilterTemplate} 
              />
              <Column
                field="createdDate"
                header="uploaded Date"
            //     showFilterMatchModes={false} showApplyButton={false} showClearButton={false} filterMenuStyle={{ minWidth: '14rem' }}
            //   filter filterElement={DismonthFilterTemplate}
              />
              <Column
                  field="Action"
                  header="Action"
                  body={actionBodyTemplate}
                />
            </DataTable>

                    {/* Start:: view Modal */}
                    {/* <div className={`tw-modal ${viewDataModal ? 'flex' : 'hidden'}`}>
                        <div className="tw-modal-content reverse-mis-modal">
                            <div className="tw-modal-title">
                                <div>Reverse MIS Detail</div>
                                <button onClick={() => setViewDataModal(false)}>
                                    <img src="assets/close-outline.svg" alt="" />
                                </button>
                            </div>

                            <div className="m-4">
                                <TableComponent
                                    rows={viewData}
                                    columns={viewDataColumns}
                                    state={viewDataState}
                                    renderColumn={(column: any) => {
                                        return (
                                            <TableCell
                                                align="center"
                                                className="!bg-gray-200"
                                                key={column.id}
                                            >
                                                {column.label}
                                            </TableCell>
                                        )
                                    }}
                                    renderRow={(row: any, index: number) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.file_sequence_num}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.pymt_prod_type_code}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.pymt_mode}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.debit_acct_no}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.beneficiary_name}
                                                </TableCell>

                                                <TableCell align="center" component="th" scope="row">
                                                    {row.beneficiary_account_no}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.bene_ifsc_code}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.amount}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.debit_narration}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.credit_narration}
                                                </TableCell>

                                                <TableCell align="center" component="th" scope="row">
                                                    {row.mobile_number}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.email_id}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.remark}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.pymt_date}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.reference_no}
                                                </TableCell>

                                                <TableCell align="center" component="th" scope="row">
                                                    {row.addl_info1}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.addl_info2}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.addl_info3}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.addl_info4}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.addl_info5}
                                                </TableCell>

                                                <TableCell align="center" component="th" scope="row">
                                                    {row.status}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.current_step}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.file_name}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.rejected_by}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.rejection_reason}
                                                </TableCell>

                                                <TableCell align="center" component="th" scope="row">
                                                    {row.acct_debit_date}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.customer_ref_no}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.utr_no}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }}
                                />
                            </div>
                        </div>
                    </div> */}
                    {/* End:: view Modal */}
                </div>
            </>
        }
    </div>
    )
}

export default ReverseMIS