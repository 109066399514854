export const PERMISSION_TYPE = {
    ADD_LOGIN_CODE: "ADD_LOGIN_CODE",
    ADD_PAYOUT_GRID: "ADD_PAYOUT_GRID",
    CONNECTOR_EDIT: "CONNECTOR_EDIT",
    ADMIN: "ADMIN",
    USER: "USER",
    STAFF_OPS: "STAFF_OPS",
    STAFF: "STAFF",
    CONNECTOR: "CONNECTOR",
    BOOKING_PARTNER:"BOOKING_PARTNER",
    SIDEBAR_DASHBOARD: "SIDEBAR_DASHBOARD",
    SIDEBAR_USER_DATABASE: "SIDEBAR_USER_DATABASE",
    SIDEBAR_STAFF_LIST: "SIDEBAR_STAFF_LIST",
    SIDEBAR_USER_REFFERED: "SIDEBAR_USER_REFFERED",
    SIDEBAR_KYC_STATUS: "SIDEBAR_KYC_STATUS",
    SIDEBAR_KYC_ADMIN: "SIDEBAR_KYC_ADMIN",
    SIDEBAR_LOGIN_CODE_LIST: "SIDEBAR_LOGIN_CODE_LIST",
    SIDEBAR_PAYOUT_GRID: "SIDEBAR_PAYOUT_GRID",
    SIDEBAR_SM_LIST: "SIDEBAR_SM_LIST",
    SIDEBAR_CONNECTOR_LIST: "SIDEBAR_CONNECTOR_LIST",
    SIDEBAR_DISBURSEMENT_DATA: "SIDEBAR_DISBURSEMENT_DATA",
    SIDEBAR_BANK_DISBURSEMENT_DATA: "SIDEBAR_BANK_DISBURSEMENT_DATA",
    SIDEBAR_LOGIN_CREDENTIALS: "SIDEBAR_LOGIN_CREDENTIALS",
    SIDEBAR_INCOME_EXPENCE: "SIDEBAR_INCOME_EXPENCE",
    SIDEBAR_INCENTIVE: "SIDEBAR_INCENTIVE",
    ADVANCE_ACTION_UPLOAD: "ADVANCE_ACTION_UPLOAD",
    ADVANCE_ACTION_APPROVE: "ADVANCE_ACTION_APPROVE",
    ADVANCE_ACTION_REJECT: "ADVANCE_ACTION_REJECT",
    SIDEBAR_BASIC_DETAILS: "SIDEBAR_BASIC_DETAILS",
    SIDEBAR_REVERSE_MIS: "SIDEBAR_REVERSE_MIS",
    SIDEBAR_REVIEW_LOSS:"SIDEBAR_REVIEW_LOSS",
    SIDEBAR_COMPANY_PAYOUT_GRID:"SIDEBAR_COMPANY_PAYOUT_GRID",
    SIDEBAR_BOOKING_PARTNER:"SIDEBAR_BOOKING_PARTNER",
    SIDEBAR_CAMPAIGN_DETAILS:"SIDEBAR_CAMPAIGN_DETAILS",
    SIDEBAR_RECONCILECASE_DATA:"SIDEBAR_RECONCILECASE_DATA",
    SIDEBAR_INVOICE_MIS_DETAIL:"SIDEBAR_INVOICE_MIS_DETAIL",
    SIDEBAR_INVOICE_ADMIN_DATA:"SIDEBAR_INVOICE_ADMIN_DATA",
}

export const userPermission = (currentUser: any, type: string) => {
    const isAdmin = currentUser?.role === 'admin';
    const isUser = currentUser?.role === 'user';
    const isConnector = currentUser?.role === 'connector';
    const isSalesStaff = currentUser?.role === 'staff_sales';
    const isBookingPartner = currentUser?.role === 'booking_partner';
    const isOpsStaff = currentUser?.role === 'staff_operations';
    const isLocationStaff = currentUser?.role === 'staff' && currentUser?.department === 'location';
    switch (type) {
        case PERMISSION_TYPE.ADMIN:
            return isAdmin
        case PERMISSION_TYPE.USER:
            return isUser
        case PERMISSION_TYPE.STAFF_OPS:
            return isOpsStaff
        case PERMISSION_TYPE.STAFF:
            return isSalesStaff
        case PERMISSION_TYPE.CONNECTOR:
            return isConnector
        case PERMISSION_TYPE.BOOKING_PARTNER:
                return isBookingPartner

        case PERMISSION_TYPE.ADD_LOGIN_CODE:
            return isAdmin || isOpsStaff
        case PERMISSION_TYPE.ADD_PAYOUT_GRID:
            return isAdmin || isOpsStaff
        case PERMISSION_TYPE.CONNECTOR_EDIT:
            return isAdmin || isUser || isSalesStaff || isLocationStaff || isOpsStaff
        case PERMISSION_TYPE.ADVANCE_ACTION_UPLOAD:
            return isAdmin || isSalesStaff || isLocationStaff || isOpsStaff
        case PERMISSION_TYPE.ADVANCE_ACTION_APPROVE:
            return isAdmin || isOpsStaff
        case PERMISSION_TYPE.ADVANCE_ACTION_REJECT:
            return isAdmin || isOpsStaff


        case PERMISSION_TYPE.SIDEBAR_DASHBOARD:
            return isAdmin || isSalesStaff || isLocationStaff || isUser || isConnector || isOpsStaff || isBookingPartner
        case PERMISSION_TYPE.SIDEBAR_USER_DATABASE:
            return isAdmin || isSalesStaff || isLocationStaff || isOpsStaff || isBookingPartner
        case PERMISSION_TYPE.SIDEBAR_STAFF_LIST:
            return isAdmin 
        case PERMISSION_TYPE.SIDEBAR_USER_REFFERED:
            return isAdmin || isUser || isLocationStaff 
        case PERMISSION_TYPE.SIDEBAR_KYC_STATUS:
            return isUser || isConnector || isBookingPartner
        case PERMISSION_TYPE.SIDEBAR_KYC_ADMIN:
            return isAdmin || isLocationStaff || isSalesStaff || isBookingPartner
        case PERMISSION_TYPE.SIDEBAR_LOGIN_CODE_LIST:
            return isAdmin || isUser || isLocationStaff || isOpsStaff || isBookingPartner || isSalesStaff
        case PERMISSION_TYPE.SIDEBAR_PAYOUT_GRID:
            return isAdmin || isUser || isLocationStaff || isOpsStaff || isConnector || isSalesStaff || isBookingPartner
        case PERMISSION_TYPE.SIDEBAR_SM_LIST:
            return isAdmin || isLocationStaff || isOpsStaff || isSalesStaff || isBookingPartner
        // case PERMISSION_TYPE.SIDEBAR_CONNECTOR_LIST:
        //         return isAdmin || isUser || isLocationStaff    
        case PERMISSION_TYPE.SIDEBAR_CONNECTOR_LIST:
            return isUser || isLocationStaff
        case PERMISSION_TYPE.SIDEBAR_DISBURSEMENT_DATA:
            return isUser ||isAdmin|| isSalesStaff || isLocationStaff || isConnector || isOpsStaff || isBookingPartner
        case PERMISSION_TYPE.SIDEBAR_BANK_DISBURSEMENT_DATA:
            return isAdmin || isOpsStaff || isSalesStaff
        case PERMISSION_TYPE.SIDEBAR_REVERSE_MIS:
            return isAdmin
        case PERMISSION_TYPE.SIDEBAR_LOGIN_CREDENTIALS:
            return isAdmin || isLocationStaff || isOpsStaff
        case PERMISSION_TYPE.SIDEBAR_INCOME_EXPENCE:
            return isLocationStaff
        case PERMISSION_TYPE.SIDEBAR_INCENTIVE:
            return isLocationStaff
        case PERMISSION_TYPE.SIDEBAR_BASIC_DETAILS:
            return isAdmin || isOpsStaff
        case PERMISSION_TYPE.SIDEBAR_REVIEW_LOSS:
                return isAdmin  
        case PERMISSION_TYPE.SIDEBAR_COMPANY_PAYOUT_GRID:
                    return isAdmin || isOpsStaff  || isSalesStaff  
        case PERMISSION_TYPE.SIDEBAR_BOOKING_PARTNER:
                    return isAdmin || isOpsStaff
        case PERMISSION_TYPE.SIDEBAR_CAMPAIGN_DETAILS:
                    return isAdmin
        case PERMISSION_TYPE.SIDEBAR_RECONCILECASE_DATA:
                    return isAdmin                     
        case PERMISSION_TYPE.SIDEBAR_INVOICE_MIS_DETAIL:
                    return isAdmin  || isOpsStaff 
        case PERMISSION_TYPE.SIDEBAR_INVOICE_ADMIN_DATA:
                     return isAdmin  || isOpsStaff                             
               
    }
}